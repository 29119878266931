import { getAccessToken } from 'neofusion-fe-shared';
import { useCallback, useEffect, useState } from 'react';
import { postData } from '../utils/api';

const EVENT_MESSAGES = {
  ENTER_MOBILE_GAME: 'enterMobileViewGame',
  EXIT_MOBILE_GAME: 'exitMobileViewGame',
};

export const useIFrameData = () => {
  const [isMobileViewGameOpen, setIsMobileViewGameOpen] = useState<boolean>(false);
  const [url, setURL] = useState<string>('');

  const handleMessage = useCallback((event: MessageEvent) => {
    switch (event.data) {
      case EVENT_MESSAGES.ENTER_MOBILE_GAME:
        setIsMobileViewGameOpen(true);
        break;
      case EVENT_MESSAGES.EXIT_MOBILE_GAME:
        setIsMobileViewGameOpen(false);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', (event) => handleMessage(event));
    return () => {
      window.removeEventListener('message', (event) => handleMessage(event));
    };
  }, [handleMessage]);

  useEffect(() => {
    const fetchURL = async () => {
      try {
        const token = await getAccessToken();
        const response = await postData('/marketplace/generateUrl', { token }, 'casino');
        setURL(response?.url);
      } catch (error) {
        console.error(error);
      }
    };

    fetchURL();
  }, [setURL]);

  return {
    isMobileViewGameOpen,
    url,
  };
};
