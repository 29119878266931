import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { SanityDocument } from '@sanity/client';
import { StyleObj } from '../../@types';
import { useSanity } from '../../hooks/useSanity';

const styles: StyleObj = {
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'neutral.600',
    pt: {
      xs: 2,
      md: 4,
    },
    pb: 6,
  },
  title: {
    color: 'primary.100',
    typography: {
      xs: 'body3',
      md: 'body2',
    },
  },
};

const Footer = () => {
  const { data: cmsContent } = useSanity<SanityDocument>('PortableTextContent');

  const determineLinkForwarding = (item: SanityDocument) => {
    if (item?.callToActionURL) {
      return item.callToActionURL;
    }
    return `/content/${item.path}`;
  };

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.title}>
        {cmsContent?.map((item, index) => {
          return (
            <React.Fragment key={item._id}>
              {index > 0 && ' | '}
              <Box
                component={Link}
                to={determineLinkForwarding(item)}
                sx={{ textDecoration: 'inherit', color: 'inherit' }}
              >
                {item.title}
              </Box>
            </React.Fragment>
          );
        })}
      </Typography>
    </Box>
  );
};

export default Footer;
