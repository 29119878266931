import { isEmpty } from 'lodash-es';
import { PublishStatus, Statistic } from '../@types';
import { BetslipEvent } from '../contexts/BetslipContext';
import { MessageType } from '../hooks/useWebsocket';

export enum OddsChangeStatus {
  'unchanged',
  'increased',
  'decreased',
}

export enum OutcomeChangeStatus {
  'unchanged',
  'activated',
  'deactivated',
}

export enum MarketChangeStatus {
  'unchanged',
  'activated',
  'deactivated',
}

export enum MatchChangeStatus {
  'unchanged',
  'activated',
  'deactivated',
  'started',
}

export type MessageBetType = {
  odds?: string;
  id: string;
  publishedStatus?: PublishStatus;
  isActive?: boolean;
  isAvailable?: boolean;
  statistics?: Statistic;
};

export type ParsedBetMessage = {
  oddsChange: OddsChangeStatus;
  outcomeChange: OutcomeChangeStatus;
  marketChange: MarketChangeStatus;
  matchChange: MatchChangeStatus;
  disabled: boolean;
  odds: string;
  singlesStakeAmount?: string | number;
};

const getChangeStatus = (next: MessageType<MessageBetType>) => {
  return next.payload.isActive ? 1 : 2;
};

const getOutcomeChangeStatus = (next: MessageType<MessageBetType>) => {
  return next.payload.isActive && next.payload.isAvailable ? 1 : 2;
};

const betslipMessageParser = (prev: BetslipEvent, next: MessageType<MessageBetType>): ParsedBetMessage => {
  let oddsChange = prev.oddsChange || OddsChangeStatus.unchanged;
  let outcomeChange = prev.outcomeChange || OutcomeChangeStatus.unchanged;
  let marketChange = prev.marketChange || MarketChangeStatus.unchanged;
  let matchChange = prev.matchChange || MatchChangeStatus.unchanged;
  let disabled = prev.disabled ?? false;
  let odds = prev.odds;

  if (!next || isEmpty(next?.payload)) {
    return {
      ...prev,
      oddsChange,
      outcomeChange,
      marketChange,
      matchChange,
      disabled,
    };
  }

  if (next.event === 'OUTCOME') {
    const prevOdds = parseFloat(prev.odds || '0');
    const nextOdds = parseFloat(next?.payload?.odds || '0');
    odds = next.payload?.odds || prev.odds;

    if (nextOdds && prevOdds !== nextOdds) {
      oddsChange = prevOdds < nextOdds ? OddsChangeStatus.increased : OddsChangeStatus.decreased;
    }
    if ('isActive' in next.payload || 'isAvailable' in next.payload) {
      outcomeChange = getOutcomeChangeStatus(next);

      disabled = outcomeChange === OutcomeChangeStatus.deactivated;
    }
  } else if (next.event === 'MARKET' && 'isActive' in next.payload) {
    marketChange = getChangeStatus(next);
  } else if (next.event === 'EVENT' && 'isActive' in next.payload) {
    matchChange = getChangeStatus(next);
  }

  return {
    ...prev,
    odds,
    oddsChange,
    outcomeChange,
    marketChange,
    matchChange,
    disabled,
  };
};

export default betslipMessageParser;
