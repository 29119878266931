import uniqueId from 'lodash-es/uniqueId';
import { OptionsObject, useSnackbar, VariantType } from 'notistack';
import { getBettingSlipSnackbarMessage } from '../helpers';
import { BettingSlipAcceptStatus } from '../@types';

export const useExtendedSnackbar = () => {
  const snackbarContext = useSnackbar();

  const enqueueWithDelay = (message: string, options?: OptionsObject<VariantType>, delay = 0) => {
    const enqueue = () => snackbarContext.enqueueSnackbar(message, options);

    if (delay > 0) {
      setTimeout(enqueue, delay);
    } else {
      enqueue();
    }
  };

  const showTicketAuthorizationCountMessage = (status: BettingSlipAcceptStatus, count: number, delay = 0) => {
    const options: OptionsObject<VariantType> = {
      key: ['rejected', 'pending'].includes(status) ? uniqueId('betting-slip') : undefined,
      variant: status === 'accepted' ? 'success' : 'warning',
    };

    const message = getBettingSlipSnackbarMessage(status, count);
    enqueueWithDelay(message, options, delay);
  };

  return { ...snackbarContext, enqueueWithDelay, showTicketAuthorizationCountMessage };
};
