import React from 'react';
import CMSSideItem from '../molecules/CMSSideItem';
import { Box } from '@mui/material';
import { SanitySideItem } from '../../@types';
import { useSanity } from '../../hooks/useSanity';

const CMSSideContent = () => {
  const { data: cmsContent } = useSanity<SanitySideItem>('PromotionalSideContent');

  return (
    <Box
      sx={{
        pt: 8,
        height: '100vh',
        position: 'relative',
        display: 'flex',
        flex: '0 0 270px',
        overflowY: 'scroll',
        flexDirection: 'column',
      }}
    >
      {cmsContent?.map((item) => <CMSSideItem key={item.title} item={item} />)}
    </Box>
  );
};

export default CMSSideContent;
