import { Box } from '@mui/material';

const CarouselScrollIndicator = ({ isActive }: { isActive: boolean }) => {
  return (
    <Box
      sx={{
        height: 5,
        width: 5,
        borderRadius: '50%',
        backgroundColor: isActive ? 'neutral.200' : 'neutral.500',
        mx: 0.2,
        transition: 'all .25s ease',
        transitionDelay: '.5s',
      }}
    />
  );
};

export default CarouselScrollIndicator;
