import { Stack } from '@mui/material';
import { StyleObj } from '../../@types';
import { useJackpotPools } from '../../queries';
import JackpotIcon from '../icons/JackpotIcon';
import JackpotInfo from './JackpotInfo';

const styles: StyleObj = {
  listContainer: {
    width: {
      xs: '100%',
      md: 'auto',
    },
    position: 'relative',
    alignItems: 'center',
    justifyContent: {
      xs: 'start',
      md: 'center',
    },
    m: 'auto',
    overflowX: {
      xs: 'scroll',
      md: 'unset',
    },
    p: {
      xs: 0.5,
      md: 1,
    },
    gap: {
      xs: 0.5,
      md: 1,
    },
  },
};

const JackpotList = () => {
  const { data: jackpotData } = useJackpotPools();

  return (
    <Stack direction='row' sx={styles.listContainer}>
      {jackpotData?.map((jackpotPool) => {
        return (
          <JackpotInfo
            jackpotPool={jackpotPool}
            key={jackpotPool.id}
            icon={<JackpotIcon type={jackpotPool.icon} size='small' />}
          />
        );
      })}
    </Stack>
  );
};

export default JackpotList;
