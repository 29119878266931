import { MenuItemType, TabConfigItem } from '../@types';
import HistoryIcon from '../components/icons/HistoryIcon';
import MessagesIcon from '../components/icons/MessagesIcon';
import { EuroOutlined, LockOutlined } from '@mui/icons-material';
import { ReactElement } from 'react';
import { LANGUAGES, ODD_CHANGES, ODDS_FORMAT } from '../constants';

type AccountMenuItem = {
  label?: string;
  value: string;
  icon?: ReactElement;
  sectionLabel?: string;
  to?: string;
  children?: MenuItemType[];
};

export type AccountMenuTab = 'my-account' | 'preferences';

export const ACCOUNT_TABS: TabConfigItem<AccountMenuTab>[] = [
  {
    label: 'Account',
    value: 'my-account',
  },
  {
    label: 'Preferences',
    value: 'preferences',
  },
];

export const ACCOUNT_MENU_ITEM_CONFIG: Record<AccountMenuTab, AccountMenuItem[]> = {
  'my-account': [
    { label: 'Bonus', value: 'bonus', icon: <EuroOutlined />, to: '/my-account/bonus' },
    { label: 'Change Password', value: 'change-password', icon: <LockOutlined />, to: '/my-account/change-password' },
    { label: 'History', value: 'history', icon: <HistoryIcon />, to: '/my-account/history' },
    { label: 'Messages', value: 'messages', icon: <MessagesIcon />, to: '/my-account/messages' },
  ],
  preferences: [
    {
      sectionLabel: 'Select Language',
      value: 'language',
      children: LANGUAGES,
    },
    {
      sectionLabel: 'Timezone',
      value: 'timeZone',
      children: [
        {
          key: 'Central European Time (CET)',
          label: 'Central European Time (CET)',
        },
      ],
    },
    {
      sectionLabel: 'Accept odd changes',
      value: 'oddsChanges',
      children: ODD_CHANGES,
    },
    {
      sectionLabel: 'Odds format',
      value: 'oddsFormat',
      children: ODDS_FORMAT,
    },
  ],
};
