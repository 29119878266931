import { List, ListItem, ListItemButton, ListItemIcon, Typography } from '@mui/material';
import SportsIcon from './SportsIcon';
import { DrawerContentProps, Sport, StyleObj } from '../../@types';
import { useNavigate, useParams } from 'react-router-dom';
import useBreakpoints from '../../hooks/useBreakpoints';

const styles: StyleObj = {
  listItem: {
    p: 0,
    ':hover': {
      backgroundColor: (theme) => theme.palette.neutral[600],
    },
    '& .MuiButtonBase-root': {
      px: {
        xs: 2,
        md: 3,
      },
      py: {
        xs: 1.5,
        md: 2,
      },
    },
  },
  listItemButton: {
    '& .MuiListItemIcon-root': {
      minWidth: '36px',
    },
    alignItems: 'center',
  },
};

const BettingDrawerContent = ({ data, toggleVisibility }: DrawerContentProps<Sport[]>) => {
  const navigate = useNavigate();
  const { sportId } = useParams();
  const { isMobile } = useBreakpoints();

  const navigateTo = (id: string, name: string) => {
    navigate(`/sports/${id}`, {
      state: {
        name,
      },
    });
    toggleVisibility(false);
  };

  return (
    <List>
      <ListItem>
        <Typography color='info.main' variant='h5'>
          FULL LIST
        </Typography>
      </ListItem>
      {data?.map(({ id, icon, name }) => (
        <ListItem key={id} sx={styles.listItem}>
          <ListItemButton disableRipple sx={styles.listItemButton} onClick={() => navigateTo(id, name)} disableGutters>
            <ListItemIcon>
              <SportsIcon name={icon} size={isMobile ? 18 : 24} />
            </ListItemIcon>
            <Typography
              noWrap
              title={name}
              sx={{
                typography: {
                  xs: sportId === id ? 'h5' : 'body3',
                  md: sportId === id ? 'h4' : 'subtitle1',
                },
              }}
            >
              {name}
            </Typography>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default BettingDrawerContent;
