import { isEmpty } from 'lodash-es';
import { EventPartScore, PublishedEventListItem } from '../@types';
import { MessageType } from '../hooks/useWebsocket';
import { updateEvent, updateEventPartScores } from '../utils';

export type MessageBetType = {
  odds?: string;
  id: string;
  isActive?: boolean;
  matchTime?: string;
  valueHome?: number;
  valueAway?: number;
  name?: string;
  shortName?: string;
};

type PublishedEventWithScores = PublishedEventListItem & {
  eventPartScores?: EventPartScore[];
};

const eventListItemUpdater = (
  prev: PublishedEventWithScores & {
    eventPartScores?: EventPartScore[];
  },
  next: MessageType<MessageBetType>,
  callback?: () => void
): PublishedEventWithScores => {
  if (!prev || !next) {
    return prev;
  }

  const { event, payload } = next;

  if (isEmpty(event) || isEmpty(payload)) {
    return prev;
  }

  if (event === 'EVENT') {
    prev = updateEvent(prev, payload) as PublishedEventWithScores;
  }

  if (event === 'MARKET') {
    if ('isActive' in payload && prev.topMarket) {
      prev.topMarket.isActive = payload.isActive || false;
    }
  }
  if (event === 'EVENT_PART_SCORE') {
    const { valueHome, valueAway } = payload;

    if (next.payload.id === prev.mainEventPartScore?.id) {
      prev.mainEventPartScore = {
        ...prev.mainEventPartScore,
        valueHome: valueHome || prev.mainEventPartScore?.valueHome,
        valueAway: valueAway || prev.mainEventPartScore?.valueAway,
      };
    }

    if (prev.eventPartScores) {
      prev.eventPartScores = updateEventPartScores(prev.eventPartScores, payload, callback);
    }
  }

  return { ...prev };
};

export default eventListItemUpdater;
