import { ThemeProvider as MuiThemeProvider, useTheme } from '@mui/material/styles';
import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import WebFont from 'webfontloader';
import { DEFAULT_THEME } from '../constants/marketplace';
import { createCustomTheme } from '../styles/theme';
import { ThemeOptions, themes } from '../styles/themeConfig';

type ThemeContextType = {
  selectedTheme: ThemeOptions;
  setTheme: (themeName: ThemeOptions) => void;
};

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
    body4: true;
  }
}

export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  const theme = useTheme();

  if (!context) {
    throw new Error('useThemeContext must be used within a ThemeProvider');
  }

  return {
    ...context,
    theme,
  };
};

export const CustomThemeProvider = ({ children }: PropsWithChildren) => {
  const [selectedTheme, setSelectedTheme] = useState<ThemeOptions>(DEFAULT_THEME);

  const themeConfig = themes[selectedTheme];

  useEffect(() => {
    const { typography } = themeConfig;

    WebFont.load({
      google: {
        families: [
          `${typography.h1.fontFamily}:300,400,500,600,700`,
          `${typography.body1.fontFamily}:300,400,500,600,700`,
        ],
      },
    });
  }, [themeConfig]);

  const theme = useMemo(() => createCustomTheme(selectedTheme), [selectedTheme]);

  return (
    <ThemeContext.Provider value={{ selectedTheme, setTheme: setSelectedTheme }}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};
