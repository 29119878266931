import { Box, Stack, Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { BetslipStatus, HistoryRowData, StyleObj } from '../../@types';
import { CURRENCY, RESOLVE_STATUS_COLORS } from '../../constants';
import useBreakpoints from '../../hooks/useBreakpoints';
import BetHistoryCollapsibleCell from '../molecules/BetHistoryCollapsibleCell';
import CasinoHistoryCollapsibleCell from '../molecules/CasinoHistoryCollapsibleCell';
import HistoryStatusCell from '../atoms/HistoryStatusCell';

const makeStyles = (isOpen: boolean, status: string): StyleObj => ({
  statusCell: {
    borderLeft: `3px solid ${RESOLVE_STATUS_COLORS[status as BetslipStatus]} !important`,
  },
  triangle: {
    position: 'absolute',
    top: '40%',
    left: 0,
    transform: 'translate(-50%) rotate(-45deg)',
    backgroundColor: 'neutral.600',
    height: {
      xs: '7px',
      md: '15px',
    },
    width: {
      xs: '7px',
      md: '15px',
    },
  },
  actionCell: (theme) => ({
    position: 'relative',
    background: isOpen ? `${theme.palette.neutral[600]} !important` : `${theme.palette.neutral[800]} !important`,
    borderRight: isOpen
      ? `1px solid ${theme.palette.neutral[600]} !important`
      : `1px solid ${theme.palette.neutral[800]} !important`,
    cursor: 'pointer',
  }),
});

const BET_TYPE_MAP: { [key: string]: string } = {
  pay_out: 'Won',
  pay_in: 'Bet',
};

type Props = {
  row: HistoryRowData;
};

const getStatus = (row: HistoryRowData) => {
  if (row.jackpot) return 'others';
  return row.status ?? 'won';
};

const HistoryTableRow = ({ row }: Props) => {
  const [open, setOpen] = useState(false);

  const styles = makeStyles(open, getStatus(row));
  const { isMobile } = useBreakpoints();

  useEffect(() => {
    setOpen(false);
  }, [row]);

  return (
    <>
      <TableRow>
        <TableCell sx={styles.statusCell}>
          <Stack alignItems='center'>
            <Typography variant='body2' fontWeight={600}>
              {row.betId ? row.betType : BET_TYPE_MAP[row.betType]}
            </Typography>
            {row.ticketId && (
              <Typography variant='body2' noWrap>
                ID: {row.ticketId}
              </Typography>
            )}
          </Stack>
        </TableCell>
        <TableCell align='center'>
          <Typography variant='body2'>
            {dayjs(row.dateTime).format(isMobile ? 'DD/MM/YY HH:mm' : 'DD/MM/YYYY HH:mm')}
          </Typography>
        </TableCell>
        <TableCell align='center'>
          <Stack alignItems='center'>
            <Typography variant='body2' fontWeight={600}>
              {CURRENCY.symbol}
              {row.stakeAmount?.toFixed(2)}
            </Typography>
            <Typography variant='body2'>Amount</Typography>
          </Stack>
        </TableCell>
        <TableCell align='center'>
          <Stack alignItems='center'>
            <Typography variant='body2' fontWeight={600}>
              {CURRENCY.symbol}
              {row.possibleReturn ?? row.previousBalance?.toFixed(2)}
            </Typography>
            <Typography variant='body2'>{row.possibleReturn ? 'Poss. Win' : 'Prev. Balance'}</Typography>
          </Stack>
        </TableCell>
        <TableCell align='center' onClick={() => setOpen((prevState) => !prevState)} sx={styles.actionCell}>
          <Box sx={styles.triangle} />
          <Stack alignItems='center'>
            {row.status ? (
              <HistoryStatusCell status={!!row.jackpot ? 'others' : row.status} winnings={row.winnings ?? 0} />
            ) : (
              <>
                <Typography variant='body2'>
                  {CURRENCY.symbol}
                  {row.balance?.toFixed(2)}
                </Typography>
                <Typography variant='body2'>Balance</Typography>
              </>
            )}
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow>
        {row.betId && <BetHistoryCollapsibleCell open={open} betslipId={row.betId} />}
        {row.roundId && (
          <CasinoHistoryCollapsibleCell
            open={open}
            roundId={row.roundId}
            name={row.gameName as string}
            provider={row.providerName as string}
          />
        )}
      </TableRow>
    </>
  );
};

export default HistoryTableRow;
