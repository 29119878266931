import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import { StyleObj } from '../../@types';
import { Dayjs } from 'dayjs';

const styles: StyleObj = {
  datePicker: (theme) => ({
    width: 400,
    px: {
      xs: 1.5,
      md: 0,
    },
    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      WebkitTextFillColor: theme.palette.text.primary,
      color: 'text.primary',
      fontSize: {
        xs: '12px',
        md: 'initial',
      },
      padding: {
        xs: '12px',
        md: '16px',
      },
    },
    '& .MuiTextField-root-input': {
      padding: {
        xs: '6px',
        md: '16px',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.neutral[300]} !important`,
    },
    '& .MuiButtonBase-root': {
      color: 'neutral.300',
    },
  }),
  layout: {
    '.MuiDayCalendar-weekDayLabel': {
      color: 'text.primary',
    },
    '.MuiPickersCalendarHeader-switchViewButton': {
      color: 'text.primary',
    },
    '.MuiPickersArrowSwitcher-root > button > svg': {
      fill: 'white',
    },
    '.MuiPickersDay-root:hover': {
      backgroundColor: 'primary.700',
    },
    '.MuiPickersDay-root:not(.Mui-selected)': {
      borderColor: 'white',
    },
  },
};

const StyledDatePicker = (props: DatePickerProps<Dayjs>) => (
  <DatePicker
    sx={styles.datePicker}
    slotProps={{
      field: { readOnly: true },
      layout: {
        sx: styles.layout,
      },
    }}
    desktopModeMediaQuery='@media (min-width: 0)'
    closeOnSelect
    {...props}
  />
);

export default StyledDatePicker;
