import { Grid, Stack, Typography } from '@mui/material';
import { Outcome, StyleObj } from '../../@types';
import { useBetslip } from '../../contexts/BetslipContext';

const makeStyles = (selected: boolean, disabled: boolean): StyleObj => ({
  outcomeCell: (theme) => ({
    py: 1.5,
    backgroundColor: selected ? 'neutral.100' : 'neutral.600',
    cursor: disabled ? 'not-allowed' : 'pointer',
    ':hover': {
      backgroundColor: !selected && !disabled ? 'neutral.400' : undefined,
    },
    '&:not(:last-child)': {
      borderRight: `1px solid ${theme.palette.neutral[700]}`,
    },
    '& p': {
      fontSize: {
        xs: 13,
        md: 15,
      },
    },
    transition: 'all 150ms ease-in-out',
  }),
});

type EventCardOutcomeCellProps = {
  outcome: Outcome;
  outcomeCount: number;
  onClick: () => void;
};

const EventCardOutcomeCell = ({ outcome, outcomeCount, onClick }: EventCardOutcomeCellProps) => {
  const { isOutcomeSelected } = useBetslip();
  const selected = isOutcomeSelected(outcome.id);

  const disabled = Number(outcome.odds) <= 1;
  const styles = makeStyles(selected, disabled);

  const handleClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  return (
    <Grid key={outcome.id} item xs={12 / outcomeCount} sx={styles.outcomeCell} onClick={handleClick}>
      <Stack direction='row' justifyContent='center' spacing={1} px={1.5}>
        {disabled ? (
          <Typography variant='body2' color='neutral.300'>
            -
          </Typography>
        ) : (
          <>
            <Typography
              noWrap
              title={outcome.shortName}
              sx={styles.outcomeName}
              variant='body2'
              color={selected ? 'neutral.600' : 'inherit'}
            >
              {outcome.shortName}
            </Typography>
            <Typography variant='body2' color={selected ? 'primary' : 'secondary'}>
              {outcome.odds}
            </Typography>
          </>
        )}
      </Stack>
    </Grid>
  );
};

export default EventCardOutcomeCell;
