import { QueryKey, useMutation } from '@tanstack/react-query';
import { QUERY_KEYS } from '../constants';
import { useInvalidateQuery } from '../hooks/useInvalidateQuery';
import { deleteData, postData } from '../utils/api';
import { EntityType } from '../@types';

type MutationArgs = {
  id: string;
  entityType: EntityType;
  isAdd: boolean;
  mutationAction: (url: string, body: unknown) => Promise<unknown>;
};

const invalidateQueriesMap: Record<EntityType, QueryKey> = {
  'market-types': [QUERY_KEYS.markets],
  events: [QUERY_KEYS.favoriteEvents, QUERY_KEYS.publishedEvents, QUERY_KEYS.tournaments],
  tournaments: [QUERY_KEYS.tournaments, QUERY_KEYS.favoriteEvents, QUERY_KEYS.publishedEvents],
};

/*
  TODO: Could be improved by using optimistic updates,
        feedback on favorite change is slow because we're waiting for server response
*/
export const useToggleFavorite = () => {
  const invalidateQuery = useInvalidateQuery();

  const mutationFn = ({ id, entityType, mutationAction }: MutationArgs) =>
    mutationAction(`user-settings/${entityType}/favourites`, { ids: [id] });

  const { mutate, isLoading } = useMutation(mutationFn, {
    onSettled: (_data, _error, { entityType }) => {
      invalidateQuery(invalidateQueriesMap[entityType]);
    },
  });

  const toggleFavorite = (id: string, entityType: EntityType, isAdd: boolean) => {
    const mutationAction = isAdd ? postData : deleteData;
    mutate({ id, entityType, isAdd, mutationAction });
  };

  return { toggleFavorite, isLoading };
};
