import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PropsWithChildren, useEffect } from 'react';
import { SECOND } from './constants';
import { fetchCasinoAccessToken, httpCasino, createRequestInterceptor } from './utils/api';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10 * SECOND,
    },
  },
});

const ReactQueryProvider = ({ children }: PropsWithChildren) => {
  useEffect(() => {
    (async () => {
      try {
        const token = await fetchCasinoAccessToken();
        if (token) {
          httpCasino.interceptors.request.use(createRequestInterceptor(token));
        }
      } catch (error) {
        console.error('Error setting up interceptor:', error);
      }
    })();
  }, []);
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

export default ReactQueryProvider;
