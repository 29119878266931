import { createTheme } from '@mui/material/styles';
import { ThemeOptions, themes } from './themeConfig';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
    body4: true;
  }
}

export const createCustomTheme = (selectedTheme: ThemeOptions) =>
  createTheme({
    palette: themes[selectedTheme].palette,
    typography: themes[selectedTheme].typography,
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1600,
      },
    },
    components: {
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            body3: 'p',
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
              display: 'block',
              '@media (max-width: 899.95px)': {
                display: 'none',
              },
              backgroundColor: 'transparent',
              width: 16,
              border: '1px solid',
              borderColor: themes[selectedTheme].palette.neutral?.[800],
            },
            '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
              borderRadius: 8,
              backgroundColor: themes[selectedTheme].palette.neutral?.[500],
              backgroundClip: 'padding-box',
              border: '4px solid transparent',
              minHeight: 24,
            },
            '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
              backgroundColor: themes[selectedTheme].palette.neutral?.[500],
            },
            '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
              backgroundColor: themes[selectedTheme].palette.neutral?.[500],
            },
            '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
              backgroundColor: themes[selectedTheme].palette.neutral?.[500],
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 20,
            height: 40,
            fontSize: 14,
            fontWeight: 600,
            lineHeight: '20px',
            padding: '10px 24px',
            textTransform: 'none',
            boxShadow: 'none',
            ':hover': {
              boxShadow: 'none',
            },
          },
        },
        defaultProps: {
          variant: 'contained',
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            input: {
              padding: '16px',
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            background: themes[selectedTheme].palette.neutral?.[700],
          },
        },
      },
    },
  });
