import { RefObject, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash-es';

const useRefDimensions = (ref: RefObject<HTMLElement>) => {
  const [dimensions, setDimensions] = useState({ width: 1, height: 1 });

  const handleResize: ResizeObserverCallback = (entries) => {
    if (entries.length) {
      setDimensions({
        width: entries[0].contentRect.width,
        height: entries[0].contentRect.height,
      });
    }
  };

  const debouncedCallback = useMemo(() => debounce(handleResize, 250), []);

  useEffect(() => {
    if (ref.current) {
      const observer = new ResizeObserver(debouncedCallback);
      observer.observe(ref.current);
      return () => observer.disconnect();
    }
  }, [debouncedCallback, ref]);

  return dimensions;
};

export default useRefDimensions;
