import axios, { InternalAxiosRequestConfig } from 'axios';
import { getAccessToken } from 'neofusion-fe-shared';

export const getApiUrl = (apiEndpoint?: string) => {
  const hostname = window.location.hostname;
  const endPoint = apiEndpoint ? `/${apiEndpoint}` : '';
  const generatedUrl = `https://api.${hostname}${endPoint}`;
  return generatedUrl;
};

const http = axios.create({
  baseURL: process.env.REACT_APP_API_FEED_URL ?? getApiUrl('sportsbook-feed-api'),
});

const httpWallet = axios.create({
  baseURL: process.env.REACT_APP_API_WALLET_URL ?? getApiUrl('sb-wallet-api'),
});

const httpCrm = axios.create({
  baseURL: process.env.REACT_APP_API_CRM_URL ?? getApiUrl('sportsbook-crm-api'),
});

export const httpCasino = axios.create({
  baseURL: process.env.REACT_APP_API_CASINO_URL ?? getApiUrl('casino-api'),
});

export const createRequestInterceptor = (token?: string) => {
  return async (config: InternalAxiosRequestConfig) => {
    const finalToken = token || (await getAccessToken());

    if (finalToken) {
      config.headers.Authorization = `Bearer ${finalToken}`;
    }

    return config;
  };
};

http.interceptors.request.use(createRequestInterceptor());
httpWallet.interceptors.request.use(createRequestInterceptor());
httpCrm.interceptors.request.use(createRequestInterceptor());

const axiosInstance = {
  feed: http,
  wallet: httpWallet,
  crm: httpCrm,
  casino: httpCasino,
} as const;

export type ApiService = typeof axiosInstance;

const executeRequest = async (
  method: 'get' | 'post' | 'patch' | 'delete',
  path: string,
  body: unknown | null,
  apiService: keyof ApiService = 'feed',
  queryParams?: unknown,
  headers?: Record<string, string | null>
) => {
  if (method === 'get') {
    const { data } = await axiosInstance[apiService].get(path, { params: queryParams });
    return data;
  } else if (method === 'delete') {
    const { data } = await axiosInstance[apiService].delete(path, { data: body });
    return data;
  } else {
    const { data } = await axiosInstance[apiService][method](path, body);
    return data;
  }
};

export const getData = async (
  url: string,
  queryParams?: unknown,
  apiService: keyof ApiService = 'feed',
  headers?: Record<string, string | null>
) => {
  return executeRequest('get', url, null, apiService, queryParams, headers);
};

export const postData = async (url: string, body: unknown, apiService: keyof ApiService = 'feed') => {
  return executeRequest('post', url, body, apiService);
};

export const patchData = async (url: string, body: unknown, apiService: keyof ApiService = 'feed') => {
  return executeRequest('patch', url, body, apiService);
};

export const deleteData = async (url: string, body: unknown | null, apiService: keyof ApiService = 'feed') => {
  return executeRequest('delete', url, body, apiService);
};

export const hasApiMorePages = (count: number, page: number, limit: number) => {
  const pagesCount = Math.ceil(count / limit);
  return page < pagesCount;
};

export const fetchCasinoAccessToken = async () => {
  try {
    const token = await getAccessToken();
    const response = await postData('/auth/authenticate', { token }, 'casino');
    return response?.accessToken;
  } catch (error) {
    console.error(error);
  }
};
