import { PaletteOptions } from '@mui/material';
import { defaultIcons } from '../constants/icons/default';
import { greenThemeIcons } from '../constants/icons/greenTheme';

const createTypography = (fonts: { body: string; heading: string }) => ({
  fontFamily: fonts.body,
  h1: {
    fontFamily: fonts.heading,
    fontSize: 32,
    fontWeight: 600,
    lineHeight: '40px',
    letterSpacing: 0,
  },
  h2: {
    fontFamily: fonts.heading,
    fontSize: 22,
    fontWeight: 700,
    lineHeight: '28px',
    letterSpacing: 0.9,
  },
  h3: {
    fontFamily: fonts.heading,
    fontSize: 18,
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: 0.9,
  },
  h4: {
    fontFamily: fonts.heading,
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '16px',
    letterSpacing: 0.8,
  },
  h5: {
    fontFamily: fonts.heading,
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '16px',
    letterSpacing: 0.1,
  },
  h6: {
    fontFamily: fonts.body,
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '16px',
    letterSpacing: 0.5,
  },
  subtitle1: {
    fontFamily: fonts.heading,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: 0.8,
  },
  subtitle2: {
    fontFamily: fonts.body,
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '20px',
    letterSpacing: 0,
  },
  subtitle3: {
    fontFamily: fonts.heading,
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '16px',
    letterSpacing: 0.5,
  },
  body1: {
    fontFamily: fonts.body,
    letterSpacing: 0.5,
  },
  body2: {
    fontFamily: fonts.body,
    letterSpacing: 0.5,
    lineHeight: '20px',
  },
  body3: {
    fontFamily: fonts.body,
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: 0.5,
  },
  body4: {
    fontFamily: fonts.body,
    fontSize: 10,
    fontWeight: 400,
    lineHeight: '10px',
    letterSpacing: 0.5,
  },
  caption: {
    fontFamily: fonts.body,
    fontSize: '10px',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: '0.5px',
  },
});

const greenPalette: PaletteOptions = {
  primary: {
    25: '#F7FAF7',
    50: '#E7F0E7',
    100: '#A8C2B3',
    200: '#8FB29F',
    300: '#76A38B',
    400: '#5D967B',
    500: '#408B6B',
    600: '#0F6E4E',
    700: '#0E5841',
    800: '#1F4537',
    900: '#172922',

    main: '#0F6E4E',
  },
  secondary: {
    25: '#FFFAE5',
    50: '#FFF6CA',
    100: '#FFF1B0',
    200: '#FFED95',
    300: '#FEE87B',
    400: '#FEE360',
    500: '#FEDF46',
    600: '#FEDA2B',
    700: '#BFA320',
    800: '#7F6D16',
    900: '#40370B',

    main: '#FEDA2B',
  },
  error: {
    25: '#FEF0F0',
    50: '#FEE2E2',
    100: '#FED4D4',
    200: '#FEC6C6',
    300: '#FDB2B2',
    400: '#FB8C8C',
    500: '#FA6565',
    600: '#F93F3F',
    700: '#8C1D18',
    800: '#601410',
    900: '#410E0B',
  },
  neutral: {
    25: '#F8F8F8',
    50: '#F0F0F0',
    100: '#E1E1E1',
    200: '#D7D7D7',
    300: '#888888',
    400: '#707070',
    500: '#585858',
    600: '#4C4C4C',
    700: '#383838',
    800: '#2F2F2F',
    900: '#181818',
  },
  info: {
    100: '#ACFDE3',
    300: '#59FBC8',
    500: '#57E4B9',
    600: '#55CDA9',
    700: '#469F84',

    main: '#2FFABA',
  },
  background: {
    default: '#383838',
  },
  text: {
    primary: '#fff',
  },
} as const;

const darkBluePalette: PaletteOptions = {
  primary: {
    900: '#0B1846',
    800: '#161C6D',
    700: '#202194',
    600: '#2B25BB',
    500: '#4640C3',
    400: '#605CCC',
    300: '#605CCC',
    200: '#9592DD',
    100: '#B0ADE5',
    50: '#CAC9EE',

    main: '#2B25BB',
  },
  secondary: {
    25: '#FBFFF5',
    50: '#F7FEEB',
    100: '#F2FEE0',
    200: '#EEFED6',
    300: '#EAFDCC',
    400: '#E6FDC2',
    500: '#E1FCB7',
    600: '#DDFCAD',
    700: '#A6C28A',
    800: '#6F8866',
    900: '#374D43',

    main: '#DDFCAD',
  },
  error: {
    25: '#FEF0F0',
    50: '#FEE2E2',
    100: '#FED4D4',
    200: '#FEC6C6',
    300: '#FDB2B2',
    400: '#FB8C8C',
    500: '#FA6565',
    600: '#F93F3F',
    700: '#8C1D18',
    800: '#601410',
    900: '#410E0B',
  },
  neutral: {
    25: '#EAEBEC',
    50: '#D4D8DA',
    100: '#BFC4C7',
    200: '#AAB0B4',
    300: '#959DA2',
    400: '#80898F',
    500: '#6A757C',
    600: '#55626A',
    700: '#404E57',
    800: '#2B3A44',
    900: '#152732',
  },
  info: {
    100: '#BEF0FF',
    300: '#92E5FF',
    400: '#7DE0FF',
    500: '#67DBFF',
    600: '#51D6FF',
    700: '#3DA5C7',

    main: '#7DE0FF',
  },
  background: {
    default: '#2E3E48',
  },
  text: {
    primary: '#fff',
  },
} as const;

export type ThemeOptions = keyof typeof themes;

export const themes = {
  green: {
    palette: greenPalette,
    typography: createTypography({
      heading: 'Raleway',
      body: 'Open Sans',
    }),
    sportsIcons: greenThemeIcons,
  },
  darkBlue: {
    palette: darkBluePalette,
    typography: createTypography({
      heading: 'Lexend',
      body: 'Poppins',
    }),
    sportsIcons: defaultIcons,
  },
};
