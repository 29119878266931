import { useEffect, useRef, useState } from 'react';
import { getCombinations } from 'sportsbook-shared-module';
import { BETTING_TYPE_HIT_COUNT_MAP } from '../constants';
import { BetslipEvent } from '../contexts/BetslipContext';

type BetCalculationData = BetslipEvent & {
  singlesStakeAmount: number;
  marketTypeId: string;
  marketTypeCombiningIds: string[];
};

type Combinations = Record<keyof typeof BETTING_TYPE_HIT_COUNT_MAP, number>;

export const useBetslipCombinations = (
  bets: BetCalculationData[],
  currentBankerCount: number,
  showSystemBetInputs: boolean,
  drawerOpen: boolean
) => {
  const [combinations, setCombinations] = useState<Partial<Combinations>>({});
  const allCombinationsCalculatedRef = useRef(false);

  // Prevent expensive re-calculation when toggling system bet inputs without changing the number of bets or bankers
  useEffect(() => {
    allCombinationsCalculatedRef.current = false;
  }, [bets.length, currentBankerCount]);

  useEffect(() => {
    if (!drawerOpen || allCombinationsCalculatedRef.current) return;

    const getOnlyLastCombination = !showSystemBetInputs;

    const result = getCombinations(bets, getOnlyLastCombination);
    setCombinations(result);

    if (showSystemBetInputs) {
      allCombinationsCalculatedRef.current = true;
    }
  }, [bets, currentBankerCount, showSystemBetInputs, drawerOpen]);

  return combinations;
};
