import { Box } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { SanitySideItem, StyleObj } from '../../@types';
import { getSanityImage } from '../../hooks/useSanity';

type CMSSideItemProps = {
  item: SanitySideItem;
};

const styles: StyleObj = {
  imageContainer: { mt: '6px', width: '250px', height: '250px' },
};

const CMSSideItem = ({ item }: CMSSideItemProps) => {
  const src = getSanityImage(item?.image);

  return (
    <Box sx={styles.imageContainer}>
      <Link to={item?.callToActionURL ?? '/'}>
        <img
          alt={item.title}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          src={src}
          title={item.title}
        />
      </Link>
    </Box>
  );
};

export default CMSSideItem;
