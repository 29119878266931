import { isDev } from '../helpers';

type LogType = 'log' | 'info' | 'warn' | 'error';

const Log = (message: unknown, data: unknown = undefined, type: LogType = 'log') => {
  // eslint-disable-next-line
  isDev() && console[type](message, data || '');
};

export default Log;
