import { omit } from 'lodash-es';
import { useAuth } from 'neofusion-fe-shared';
import { useSnackbar } from 'notistack';
import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  Bet,
  BetslipResolutionData,
  BetslipResolutionDataPayload,
  BetslipTicketType,
  MarketType,
  SpecialValue,
} from '../@types';
import { BETSLIP_TICKET_TYPES, QUERY_KEYS } from '../constants';
import { MESSAGES } from '../constants/messages';
import { analyzeBetslip, isWaysBetslip } from '../helpers/';
import betslipMessageParser, {
  MarketChangeStatus,
  MatchChangeStatus,
  MessageBetType,
  OddsChangeStatus,
  OutcomeChangeStatus,
} from '../helpers/betslipMessageParser';
import { useInvalidateQuery } from '../hooks/useInvalidateQuery';
import useWebsocket, { MessageType } from '../hooks/useWebsocket';
import { useGlobalTicketConditions } from '../queries';

export type BetslipEvent = Bet & {
  eventName: string;
  matchChange?: MatchChangeStatus;
  marketName: string;
  marketShortName?: string;
  marketType: MarketType;
  marketChange?: MarketChangeStatus;
  outcomeChange?: OutcomeChangeStatus;
  outcomeName: string;
  outcomeShortName?: string;
  odds: string;
  oddsChange?: OddsChangeStatus;
  specialValues?: SpecialValue[];
  disabled?: boolean;
  isLive?: boolean;
};

type BetslipContextData = {
  bets: BetslipEvent[];
  addBet: (bet: BetslipEvent) => void;
  addOrRemoveBet: (bet: BetslipEvent) => void;
  removeBet: (outcomeId: string) => void;
  removeAllBets: () => void;
  isOutcomeSelected: (outcomeId: string) => boolean;
  toggleBanker: (outcomeId: string) => void;
  errors: string[];
  clearErrors: () => void;
  uniqueEventCount: number;
  isWaysTicket: boolean;
  resetBankers: () => void;
  currentBankerCount: number;
  isMaxBankerCountReached: boolean;
  infoMessages: Set<string>;
  insertCopiedBetslip: (bets: BetslipEvent[]) => void;
  updateSinglesStakeAmount: (outcomeId: string, amount: string) => void;
  resetSinglesStakeAmounts: () => void;
  joinUserRoom: () => void;
  reofferedBettingSlipsIds: string[];
  removeReofferedBettingSlip: (bettingSlipId: string) => void;
  setBettingPrevented: (value: boolean) => void;
  betslipTicketType: BetslipTicketType;
};

export const BetslipContext = createContext<BetslipContextData | undefined>(undefined);

type BetslipProviderProps = {
  children: ReactNode;
};

// FIXME: improve this function
const checkIsInBetslip = (bet: BetslipEvent, message: MessageType<MessageBetType>) => {
  if (!message) return false;

  const { event, payload } = message;
  if (!payload || !event) return false;

  return (
    (event === 'OUTCOME' && payload.id === bet.outcomeId) ||
    (event === 'MARKET' && payload.id === bet.marketId) ||
    payload.id === bet.eventId
  );
};

const checkIsDeactivated = (bet: BetslipEvent) => {
  return (
    bet.outcomeChange === OutcomeChangeStatus.deactivated ||
    bet.marketChange === MarketChangeStatus.deactivated ||
    bet.matchChange === MatchChangeStatus.deactivated
  );
};

export const BetslipProvider = ({ children }: BetslipProviderProps) => {
  const [bets, setBets] = useState<Record<string, BetslipEvent>>({});
  const [reofferedBettingSlipsIds, setReofferedBettingSlipsIds] = useState<string[]>([]);
  const [bettingPrevented, setBettingPrevented] = useState(false);

  const [errors, setErrors] = useState<{ outcomeId: string; message: string }[]>([]);
  const [infoMessages, setInfoMessages] = useState<Set<string>>(new Set());

  const { enqueueSnackbar } = useSnackbar();
  const invalidateData = useInvalidateQuery();

  const { userId } = useAuth();

  const { data: globalTicketConditions } = useGlobalTicketConditions();

  const { betCount, betslipTicketType } = useMemo(() => analyzeBetslip(Object.values(bets)), [bets]);

  const updaterCallback = useCallback((data: Record<string, MessageType<MessageBetType>>[]) => {
    data.forEach((message) => {
      setBets((prevState) => {
        const newState = Object.entries(prevState).reduce((acc, [outcomeId, bet]) => {
          const socketMessage = message[bet.eventId];

          const isBetInBetslip = checkIsInBetslip(bet, socketMessage);

          if (!isBetInBetslip) {
            return acc;
          }

          const updatedBet = betslipMessageParser(bet, socketMessage);

          // if disabled set singlesStakeAmount to undefined
          if (updatedBet.disabled) {
            updatedBet.singlesStakeAmount = undefined;
          }

          return { ...acc, [outcomeId]: updatedBet };
        }, {});

        return { ...prevState, ...newState };
      });
    });
  }, []);

  const { joinRoom, leaveRoom, resetWS } = useWebsocket<MessageBetType>({
    callback: updaterCallback,
  });

  useEffect(() => {
    const newErrors: { outcomeId: string; message: string }[] = [];

    Object.values(bets).forEach((bet) => {
      if (checkIsDeactivated(bet)) {
        newErrors.push({ outcomeId: bet.outcomeId, message: MESSAGES.availabilityChanged });
        return;
      }
      if (bet.oddsChange) {
        newErrors.push({ outcomeId: bet.outcomeId, message: MESSAGES.outcome });
      }
    });

    setErrors(newErrors);
  }, [bets]);

  const setTimedInfoMessage = useCallback((message: string, duration = 3000) => {
    setInfoMessages((prevMessages) => new Set(prevMessages).add(message));

    setTimeout(() => {
      setInfoMessages((prevMessages) => {
        const updatedMessages = new Set(prevMessages);
        updatedMessages.delete(message);
        return updatedMessages;
      });
    }, duration);
  }, []);

  const addBet = useCallback(
    (newBet: BetslipEvent) => {
      if (bettingPrevented) {
        enqueueSnackbar(MESSAGES.notAllowedToBet, {
          variant: 'info',
        });
        return;
      }

      const betType = newBet.isLive ? BETSLIP_TICKET_TYPES.inPlay : BETSLIP_TICKET_TYPES.preMatch;

      // We check if the ticket is already a mixed ticket, or if by adding a new bet of a different type it would become a mixed ticket
      const isMixTicket = betslipTicketType === BETSLIP_TICKET_TYPES.mix || betType !== betslipTicketType;

      const maxSelections = isMixTicket
        ? globalTicketConditions?.[BETSLIP_TICKET_TYPES.mix].maxSelections
        : globalTicketConditions?.[betType]?.maxSelections;
      const canAddBet = !maxSelections || betCount < maxSelections;

      if (canAddBet) {
        setBets((prevState) => ({ ...prevState, [newBet.outcomeId]: { ...newBet, banker: false } }));
        joinRoom(`${newBet.eventId}_BetSlip`);
      } else {
        setTimedInfoMessage(MESSAGES.maxSelections, 5000);
      }
    },
    [
      bettingPrevented,
      enqueueSnackbar,
      betslipTicketType,
      globalTicketConditions,
      betCount,
      joinRoom,
      setTimedInfoMessage,
    ]
  );

  const removeInfoMessage = useCallback(
    (messageToRemove: string) => {
      if (infoMessages.has(messageToRemove)) {
        setInfoMessages((prevMessages) => {
          const newMessages = new Set(prevMessages);
          newMessages.delete(messageToRemove);
          return newMessages;
        });
      }
    },
    [infoMessages]
  );

  const removeBet = useCallback(
    (outcomeId: string) => {
      const eventToRemove = bets[outcomeId].eventId;
      setBets((prevState) => omit(prevState, outcomeId));
      removeInfoMessage(MESSAGES.maxSelections);
      leaveRoom(`${eventToRemove}_BetSlip`);

      setErrors((prev) => prev.filter((error) => error.outcomeId !== outcomeId));
    },
    [bets, removeInfoMessage, leaveRoom]
  );

  const isOutcomeSelected = useCallback((outcomeId: string) => !!bets[outcomeId], [bets]);

  const addOrRemoveBet = useCallback(
    (bet: BetslipEvent) => {
      if (bettingPrevented) {
        enqueueSnackbar(MESSAGES.notAllowedToBet, {
          variant: 'info',
        });
        return;
      }
      if (isOutcomeSelected(bet.outcomeId)) {
        removeBet(bet.outcomeId);
      } else {
        addBet(bet);
      }
    },
    [bettingPrevented, enqueueSnackbar, isOutcomeSelected, removeBet, addBet]
  );

  const removeAllBets = useCallback(() => {
    setBets({});
    resetWS();
    removeInfoMessage(MESSAGES.maxSelections);
    setErrors([]);
  }, [resetWS, removeInfoMessage]);

  const uniqueEventCount = new Set(Object.values(bets).map((bet) => bet.eventId)).size;
  const currentBankerCount = useMemo(() => Object.values(bets).filter((bet) => bet.banker).length, [bets]);

  const maxBankerCount = betCount - 2;
  const isMaxBankerCountReached = betCount > 2 && currentBankerCount >= maxBankerCount;

  useEffect(() => {
    setInfoMessages((prevMessages) => {
      const newMessages = new Set(prevMessages);
      if (isMaxBankerCountReached) {
        newMessages.add(MESSAGES.maxBankers);
      } else {
        newMessages.delete(MESSAGES.maxBankers);
      }
      return newMessages;
    });
  }, [isMaxBankerCountReached]);

  const isWaysTicket = useMemo(() => {
    return isWaysBetslip(Object.values(bets));
    // We don't care about updates to the betslip content (odds etc.), only its length when adding/removing bets
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [betCount]);

  const clearErrors = useCallback(() => {
    setErrors([]);
    acceptChanges();
  }, []);

  const acceptChanges = () => {
    setBets((prevState) => {
      const newState: Record<string, BetslipEvent> = {};

      Object.entries(prevState).forEach(([key, value]) => {
        newState[key] = {
          ...value,
          oddsChange: 0,
          outcomeChange: 0,
          marketChange: 0,
          matchChange: 0,
        };
      });

      return newState;
    });
  };

  const toggleBanker = useCallback((outcomeId: string) => {
    setBets((prevState) => ({
      ...prevState,
      [outcomeId]: {
        ...prevState[outcomeId],
        banker: !prevState[outcomeId].banker,
      },
    }));
  }, []);

  const resetBankers = useCallback(() => {
    setBets((prevState) => {
      const newState: Record<string, BetslipEvent> = {};

      Object.entries(prevState).forEach(([key, value]) => {
        newState[key] = {
          ...value,
          banker: false,
        };
      });

      return newState;
    });
  }, []);

  const insertCopiedBetslip = useCallback(
    (copiedBets: BetslipEvent[]) => {
      removeAllBets();

      setBets(() => {
        const newState: Record<string, BetslipEvent> = {};

        copiedBets.forEach((bet) => {
          newState[bet.outcomeId] = bet;
          joinRoom(`${bet.eventId}_BetSlip`);
        });

        return newState;
      });

      setTimedInfoMessage(MESSAGES.ticketCopied);
    },
    [removeAllBets, joinRoom, setTimedInfoMessage]
  );

  const updateSinglesStakeAmount = useCallback((outcomeId: string, amount: string) => {
    setBets((prevState) => ({
      ...prevState,
      [outcomeId]: {
        ...prevState[outcomeId],
        singlesStakeAmount: amount,
      },
    }));
  }, []);

  const resetSinglesStakeAmounts = useCallback(() => {
    setBets((prevState) => {
      const newState: Record<string, BetslipEvent> = {};

      Object.entries(prevState).forEach(([key, value]) => {
        newState[key] = {
          ...value,
          singlesStakeAmount: undefined,
        };
      });

      return newState;
    });
  }, []);

  const removeReofferedBettingSlip = useCallback((bettingSlipId: string) => {
    setReofferedBettingSlipsIds((prev) => prev.filter((id) => id !== bettingSlipId));
  }, []);

  const userIdRoomUpdaterCallback = useCallback(
    (data: BetslipResolutionData[]) => {
      data.forEach((message) => {
        const messageData = message[userId];

        if (!messageData) {
          return;
        }

        if (messageData.payload.acceptStatus === 'rejected') {
          if (messageData.payload?.reofferedId) {
            setReofferedBettingSlipsIds((prev) => [...prev, messageData.payload.reofferedId as string]);
          } else {
            enqueueSnackbar(MESSAGES.bettingSlipRejected, {
              variant: 'warning',
            });
            // we need to check if any of the betslips is in confirmation process and
            // if so, keep the bettingPrevented flag to true
            setBettingPrevented(false);
          }
        } else if (messageData.payload.acceptStatus === 'accepted') {
          enqueueSnackbar(MESSAGES.placeBetSuccess, {
            variant: 'success',
          });
          // we need to check if any of the betslips is in confirmation process and
          // if so, keep the bettingPrevented flag to true
          setBettingPrevented(false);
        }

        if (messageData.payload.acceptStatus === 'admin_cancelled') {
          enqueueSnackbar(MESSAGES.bettingSlipRejected, {
            variant: 'warning',
          });

          invalidateData([QUERY_KEYS.balance, QUERY_KEYS.myBetsCount]);
        }
      });
    },
    [userId, enqueueSnackbar, invalidateData]
  );

  const { joinRoom: joinUserIdRoom } = useWebsocket<BetslipResolutionDataPayload>({
    callback: userIdRoomUpdaterCallback,
  });

  const joinUserRoom = useCallback(() => {
    joinUserIdRoom(userId);
  }, [joinUserIdRoom, userId]);

  const memoizedBets = useMemo(() => Object.values(bets), [bets]);

  const contextValue: BetslipContextData = {
    bets: memoizedBets,
    addBet,
    removeBet,
    removeAllBets,
    isOutcomeSelected,
    addOrRemoveBet,
    toggleBanker,
    errors: Array.from(new Set(errors?.map((error) => error.message))),
    clearErrors,
    uniqueEventCount,
    isWaysTicket,
    resetBankers,
    currentBankerCount,
    isMaxBankerCountReached,
    infoMessages,
    insertCopiedBetslip,
    updateSinglesStakeAmount,
    resetSinglesStakeAmounts,
    joinUserRoom,
    reofferedBettingSlipsIds,
    removeReofferedBettingSlip,
    setBettingPrevented,
    betslipTicketType,
  };

  return <BetslipContext.Provider value={contextValue}>{children}</BetslipContext.Provider>;
};

export const useBetslip = () => {
  const context = useContext(BetslipContext);
  if (!context) {
    throw new Error('useBetslip must be used within a BetslipProvider');
  }
  return context;
};
