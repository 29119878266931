import { Star } from '@mui/icons-material';
import { ReactComponent as Badminton } from '../../assets/icons/sports/darkBlue/badminton.svg';
import { ReactComponent as Baseball } from '../../assets/icons/sports/darkBlue/baseball.svg';
import { ReactComponent as Basketball } from '../../assets/icons/sports/darkBlue/basketball.svg';
import { ReactComponent as Boxing } from '../../assets/icons/sports/darkBlue/boxing.svg';
import { ReactComponent as Cricket } from '../../assets/icons/sports/darkBlue/cricket.svg';
import { ReactComponent as Darts } from '../../assets/icons/sports/darkBlue/darts.svg';
import { ReactComponent as Esports } from '../../assets/icons/sports/darkBlue/esports.svg';
import { ReactComponent as Floorball } from '../../assets/icons/sports/darkBlue/floorball.svg';
import { ReactComponent as Futsal } from '../../assets/icons/sports/darkBlue/futsal.svg';
import { ReactComponent as Golf } from '../../assets/icons/sports/darkBlue/golf.svg';
import { ReactComponent as Handball } from '../../assets/icons/sports/darkBlue/handball.svg';
import { ReactComponent as IceHockey } from '../../assets/icons/sports/darkBlue/ice_hockey.svg';
import { ReactComponent as MMA } from '../../assets/icons/sports/darkBlue/mma.svg';
import { ReactComponent as RugbyLeague } from '../../assets/icons/sports/darkBlue/rugby_league.svg';
import { ReactComponent as Snooker } from '../../assets/icons/sports/darkBlue/snooker.svg';
import { ReactComponent as Soccer } from '../../assets/icons/sports/darkBlue/soccer.svg';
import { ReactComponent as TableTennis } from '../../assets/icons/sports/darkBlue/table_tennis.svg';
import { ReactComponent as Tennis } from '../../assets/icons/sports/darkBlue/tennis.svg';
import { ReactComponent as Volleyball } from '../../assets/icons/sports/darkBlue/volleyball.svg';
import { ReactComponent as WaterPolo } from '../../assets/icons/sports/darkBlue/water_polo.svg';

export const defaultIcons = {
  assets: {
    badminton: Badminton,
    baseball: Baseball,
    basketball: Basketball,
    boxing: Boxing,
    cricket: Cricket,
    darts: Darts,
    esports: Esports,
    floorball: Floorball,
    futsal: Futsal,
    golf: Golf,
    handball: Handball,
    ice_hockey: IceHockey,
    mma: MMA,
    rugby_league: RugbyLeague,
    snooker: Snooker,
    soccer: Soccer,
    table_tennis: TableTennis,
    tennis: Tennis,
    volleyball: Volleyball,
    water_polo: WaterPolo,
    favorite: Star,
  },
  viewBox: '0 0 24 24',
} as const;
