var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from 'react';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
var GrowthBookProviderWrapper = function (_a) {
    var children = _a.children, apiHost = _a.apiHost, clientKey = _a.clientKey;
    var _b = useState(false), isLoaded = _b[0], setIsLoaded = _b[1];
    var growthBook = useMemo(function () {
        return new GrowthBook({ apiHost: apiHost, clientKey: clientKey });
    }, [apiHost, clientKey]);
    useEffect(function () {
        growthBook.init().finally(function () { return setIsLoaded(true); });
    }, [growthBook]);
    if (!isLoaded) {
        return null;
    }
    return _jsx(GrowthBookProvider, __assign({ growthbook: growthBook }, { children: children }));
};
export default GrowthBookProviderWrapper;
