import { Construction } from '@mui/icons-material';
import { Container, Stack, Typography } from '@mui/material';
import { StyleObj } from '../../@types';
import { MESSAGES } from '../../constants/messages';

const styles: StyleObj = {
  container: {
    maxWidth: 'sm',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
  },
  box: {
    maxWidth: 800,
    alignItems: 'center',
    justifyContent: 'center',
    gap: 2,
    m: 'auto',
    boxShadow: 2,
    p: 6,
    borderRadius: 4,
    backgroundColor: 'neutral.800',
    borderLeft: '8px solid',
    borderLeftColor: 'info.main',
  },
  icon: {
    fontSize: 100,
    color: 'info.main',
  },
  text: {
    typography: {
      xs: 'h4',
      md: 'h2',
    },
    textAlign: 'center',
  },
  subtitle: {
    typography: {
      xs: 'body3',
      md: 'subtitle1',
    },
    textAlign: 'center',
  },
};

const MaintenancePage = () => {
  return (
    <Container sx={styles.container}>
      <Stack sx={styles.box}>
        <Construction sx={styles.icon} />
        <Typography sx={styles.text}>{MESSAGES.maintenanceMode}</Typography>
        <Typography variant='subtitle1' sx={styles.subtitle}>
          {MESSAGES.maintenanceApology}
        </Typography>
      </Stack>
    </Container>
  );
};

export default MaintenancePage;
