import { Star, StarOutline } from '@mui/icons-material';
import { Checkbox, CheckboxProps } from '@mui/material';
import { EntityType, StyleObj } from '../../@types';
import { useToggleFavorite } from '../../mutations';

const makeStyles = (variant: string, uncheckedColor: string): StyleObj => {
  return {
    uncheckedIcon: {
      fontSize: variant,
      color: uncheckedColor,
    },
    checkedIcon: {
      fontSize: variant,
      color: 'secondary.main',
    },
  };
};
type Props = CheckboxProps & {
  id: string;
  isChecked: boolean;
  entityType: EntityType;
  uncheckedColor?: string;
  variant?: 'medium' | 'large' | 'xl';
};

const FavoritesCheckbox = ({
  id,
  isChecked,
  entityType,
  uncheckedColor = 'neutral.400',
  variant = 'xl',
  ...rest
}: Props) => {
  const { toggleFavorite } = useToggleFavorite();

  const styles = makeStyles(variant, uncheckedColor);

  return (
    <Checkbox
      icon={<StarOutline sx={styles.uncheckedIcon} />}
      checkedIcon={<Star sx={styles.checkedIcon} />}
      checked={isChecked}
      onChange={(e) => toggleFavorite(id, entityType, e.target.checked)}
      disableRipple
      {...rest}
    />
  );
};

export default FavoritesCheckbox;
