import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

export const useInvalidateQuery = () => {
  const queryClient = useQueryClient();

  const refetchData = useCallback(
    async (queryKeys: QueryKey) => {
      await Promise.all(queryKeys.map((queryKey) => queryClient.invalidateQueries([queryKey])));
    },
    [queryClient]
  );

  return refetchData;
};
