import { Box, Typography } from '@mui/material';

type ErrorPageProps = {
  text?: string;
};

const ErrorPage = ({ text }: ErrorPageProps) => {
  return (
    <Box display='flex' justifyContent='center' alignItems='center'>
      <Typography fontSize='30px' textAlign='center' mt={5}>
        {text || 'Page not found'}
      </Typography>
    </Box>
  );
};

export default ErrorPage;
