import { ExpandMore } from '@mui/icons-material';
import { MenuItem, Select } from '@mui/material';
import { StyleObj } from '../../../@types';

const styles: StyleObj = {
  selectInput: {
    alignSelf: 'stretch',
    color: 'primary.600',
    background: 'transparent',
    ml: 'auto',
    typography: 'body3',
    '& .MuiOutlinedInput-input': { padding: 0 },
    '& .MuiOutlinedInput-notchedOutline': { display: 'none' },
    '.MuiSvgIcon-root ': {
      fontSize: 18,
      color: 'primary.600',
      mr: 0.5,
      mt: '-1px',
    },
  },
  menu: {
    '& .MuiPaper-root': {
      borderRadius: 0,
      width: {
        xs: 100,
        md: 120,
      },
      background: 'white',
      color: 'neutral.600',
      boxShadow: '0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);',
      '& .MuiList-root': {
        padding: 0,
        '& > li': {
          borderBottom: (theme) => `1px solid ${theme.palette.neutral[100]}`,
          typography: 'body3',
          padding: 1,
        },
        '& .Mui-selected': {
          color: 'primary.600',
          borderLeft: (theme) => `2px solid ${theme.palette.primary.main}`,
          background: 'inherit',
        },
        '& .MuiButtonBase-root': {
          minHeight: 0,
        },
      },
    },
  },
};

type BetslipMenuProps = {
  selectedMenuItem: string;
  onChange: (value: string) => void;
  options: { label: string; value: string }[] | undefined;
  disabledOptions?: string[] | undefined;
  style?: React.CSSProperties | undefined;
};

const BetslipMenu = ({ selectedMenuItem, onChange, options, disabledOptions, style }: BetslipMenuProps) => {
  return (
    <Select
      value={selectedMenuItem}
      onChange={(e) => {
        onChange(e.target.value as string);
      }}
      IconComponent={(props) => <ExpandMore {...props} />}
      sx={styles.selectInput}
      MenuProps={{
        sx: styles.menu,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      }}
      style={style}
    >
      {options?.map((option) => (
        <MenuItem key={option.value} value={option.value} disabled={disabledOptions?.includes(option.value) || false}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default BetslipMenu;
