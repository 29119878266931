import { SanityTopItem } from '../../@types';
import { Content, useSanity } from '../../hooks/useSanity';
import {} from '../../utils/api';
import CMSCarouselCasinoItem from '../molecules/CMSCarouselCasinoItem';
import CMSCarouselItem from '../molecules/CMSCarouselItem';
import Carousel from './Carousel';

interface Props {
  scope?: 'sportsbook' | 'casino';
}

const CMSTopContent = ({ scope = 'sportsbook' }: Props) => {
  let contentType: Content = 'PromotionalTopContent';
  if (scope === 'casino') {
    contentType = 'CasinoPromotionalTopContent';
  }
  const { data: cmsContent } = useSanity<SanityTopItem>(contentType);

  let Component = CMSCarouselItem;
  if (scope === 'casino') {
    Component = CMSCarouselCasinoItem;
  }

  return <Carousel cms>{cmsContent?.map((item) => <Component item={item} key={item.title} />)}</Carousel>;
};

export default CMSTopContent;
