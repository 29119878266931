import { lazy } from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import Root from './components/Root';
import MarketplaceLayout from './components/layouts/MarketplaceLayout';
import CasinoHistoryPage from './components/pages/CasinoHistoryPage';
import CasinoPage from './components/pages/CasinoPage';
import ErrorPage from './components/pages/ErrorPage';
import SportsPage from './components/pages/SportsPage';

const SportPage = lazy(() => import('./components/pages/SportPage'));
const UpcomingMatchesPage = lazy(() => import('./components/pages/UpcomingMatchesPage'));
const TournamentPage = lazy(() => import('./components/pages/TournamentPage'));
const EventPage = lazy(() => import('./components/pages/EventPage'));
const InPlayEventPage = lazy(() => import('./components/pages/InPlayEventPage'));
const InPlayFavoritesPage = lazy(() => import('./components/pages/InPlayFavoritesPage'));
const InPlayPage = lazy(() => import('./components/pages/InPlayPage'));
const BettingHistoryPage = lazy(() => import('./components/pages/BettingHistoryPage'));
const PreferencesPage = lazy(() => import('./components/pages/PreferencesPage'));
const MessagesInboxPage = lazy(() => import('./components/pages/MessagesInboxPage'));
const MessagesSentPage = lazy(() => import('./components/pages/MessagesSentPage'));
const MessagesDeletePage = lazy(() => import('./components/pages/MessagesDeletePage'));
const ContentPage = lazy(() => import('./components/pages/ContentPage'));
const MyBetsPage = lazy(() => import('./components/pages/MyBetsPage'));
const TournamentMatchesPage = lazy(() => import('./components/pages/TournamentMatchesPage'));
const TournamentOutrightsPage = lazy(() => import('./components/pages/TournamentOutrightsPage'));

const router = createBrowserRouter([
  {
    element: <MarketplaceLayout />,
    children: [
      {
        path: '/',
        element: <Root />,
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            element: <Navigate to='./sports' />,
          },
          {
            path: '/sports',
            children: [
              {
                index: true,
                element: <SportsPage />,
              },
              {
                path: ':sportId',
                children: [
                  {
                    index: true,
                    element: <Navigate to='matches' />,
                  },
                  {
                    path: ':section',
                    element: <SportPage />,
                  },
                  {
                    path: 'upcoming-matches',
                    children: [
                      {
                        index: true,
                        element: <Navigate to='top-leagues' />,
                      },
                      {
                        path: ':tab',
                        element: <UpcomingMatchesPage />,
                      },
                    ],
                  },
                  {
                    path: 'tournaments/:tournamentId',
                    element: <TournamentPage />,
                    children: [
                      {
                        index: true,
                        element: <Navigate to='matches' />,
                      },
                      {
                        path: 'matches',
                        element: <TournamentMatchesPage />,
                      },
                      {
                        path: 'outrights',
                        element: <TournamentOutrightsPage />,
                      },
                    ],
                  },
                ],
              },

              {
                path: 'events/:eventId',
                children: [
                  {
                    index: true,
                    element: <Navigate to='all' />,
                  },
                  {
                    path: ':marketGroupId',
                    element: <EventPage />,
                  },
                ],
              },
            ],
          },
          {
            path: '/in-play',
            element: <InPlayPage />,
          },
          {
            path: '/in-play/favorites',
            element: <InPlayFavoritesPage />,
          },
          {
            path: '/in-play/sport/:sportId',
            element: <InPlayPage />,
          },
          {
            path: '/in-play/match-view/:eventId',
            children: [
              {
                index: true,
                element: <Navigate to='all' />,
              },
              {
                path: ':marketGroupId',
                element: <InPlayEventPage />,
              },
            ],
          },
          {
            path: '/my-bets',
            element: <MyBetsPage />,
          },
          {
            path: '/my-account',
            children: [
              {
                index: true,
                element: <Navigate to='history' />,
              },
              {
                path: 'history',
                children: [
                  {
                    index: true,
                    element: <Navigate to='sports-betting' />,
                  },
                  {
                    path: 'sports-betting',
                    element: <BettingHistoryPage />,
                  },
                  {
                    path: 'casino',
                    element: <CasinoHistoryPage />,
                  },
                ],
              },
              {
                path: 'bonus',
                children: [
                  {
                    index: true,
                    element: <Navigate to='sports' />,
                  },
                  {
                    path: 'sports',
                    element: <div>Sportsbook bonus</div>,
                  },
                  {
                    path: 'casino',
                    element: <div>Casino bonus</div>,
                  },
                ],
              },
              {
                path: 'preferences',
                element: <PreferencesPage />,
              },
              {
                path: 'change-password',
                element: <div>Change Password</div>,
              },
              {
                path: 'messages',
                children: [
                  {
                    index: true,
                    element: <Navigate to='inbox' />,
                  },
                  {
                    path: 'inbox',
                    element: <MessagesInboxPage />,
                  },
                  {
                    path: 'sent',
                    element: <MessagesSentPage />,
                  },
                  {
                    path: 'Deleted',
                    element: <MessagesDeletePage />,
                  },
                ],
              },
            ],
          },
          {
            path: '/content/:page',
            children: [
              {
                index: true,
                element: <ContentPage />,
              },
            ],
          },
          {
            path: '/casino',
            children: [
              {
                index: true,
                element: <CasinoPage />,
              },
            ],
          },
        ],
      },
    ],
  },
]);

export default router;
