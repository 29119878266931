import { Badge } from '@mui/material';
import { useUnreadMessageCount } from '../../queries';

const MessagesIcon = () => {
  const { data: unreadMessagesCount } = useUnreadMessageCount();

  return (
    <Badge
      color='error'
      badgeContent={unreadMessagesCount ?? null}
      overlap='circular'
      sx={{
        '.MuiBadge-badge': {
          top: '-5%',
          right: 0,
        },
      }}
    >
      <svg xmlns='http://www.w3.org/2000/svg' width='33' height='32' viewBox='0 0 33 32' fill='currentColor'>
        <path
          d='M12.2333 24.5184L13.1208 23.9253C13.0232 23.7795 12.8912 23.66 12.7364 23.5773C12.5816 23.4947 12.4088 23.4516 12.2333 23.4517V24.5184ZM16.5 30.9141L15.6125 31.5051C15.7099 31.6512 15.8419 31.7709 15.9967 31.8538C16.1515 31.9366 16.3244 31.98 16.5 31.98C16.6756 31.98 16.8485 31.9366 17.0033 31.8538C17.1581 31.7709 17.2901 31.6512 17.3875 31.5051L16.5 30.9141ZM20.7667 24.5184V23.4517C20.5912 23.4516 20.4184 23.4947 20.2636 23.5773C20.1088 23.66 19.9768 23.7795 19.8792 23.9253L20.7667 24.5184ZM11.3459 25.1093L15.6125 31.5051L17.3875 30.3211L13.1208 23.9253L11.3459 25.1093ZM17.3875 31.5051L21.6541 25.1093L19.8792 23.9253L15.6125 30.3211L17.3875 31.5051ZM20.7667 25.5851H29.3V23.4517H20.7667V25.5851ZM29.3 25.5851C31.0685 25.5851 32.5 24.1557 32.5 22.3851H30.3667C30.3667 22.976 29.8909 23.4517 29.3 23.4517V25.5851ZM32.5 22.3851V3.2H30.3667V22.3872H32.5V22.3851ZM32.5 3.2C32.5 1.42933 31.0685 0 29.3 0V2.13333C29.8909 2.13333 30.3667 2.60907 30.3667 3.2H32.5ZM29.3 0H3.7V2.13333H29.3V0ZM3.7 0C1.93147 0 0.5 1.42933 0.5 3.2H2.63333C2.63333 2.60907 3.10907 2.13333 3.7 2.13333V0ZM0.5 3.2V22.3872H2.63333V3.19787H0.5V3.2ZM0.5 22.3872C0.5 24.1557 1.93147 25.5851 3.7 25.5851V23.4517C3.55984 23.452 3.42101 23.4246 3.29147 23.3711C3.16193 23.3176 3.04423 23.239 2.94513 23.1399C2.84602 23.0408 2.76746 22.9231 2.71396 22.7936C2.66045 22.6641 2.63305 22.5252 2.63333 22.3851H0.5V22.3872ZM3.7 25.5851H12.2333V23.4517H3.7V25.5851ZM11.1667 17.0667H21.8333V14.9333H11.1667V17.0667ZM9.03333 10.6667H23.9667V8.53333H9.03333V10.6667Z'
          fill='currentColor'
        />
      </svg>
    </Badge>
  );
};

export default MessagesIcon;
