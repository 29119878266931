import Badge from '@mui/material/Badge';
import { useQuery } from '@tanstack/react-query';
import { StyleObj } from '../../@types';
import { QUERY_KEYS } from '../../constants';
import { getData } from '../../utils/api';

const styles: StyleObj = {
  badge: {
    position: 'absolute',
    top: 12,
    right: 4,
    '& .MuiBadge-badge': {
      fontSize: {
        xs: 10,
        md: 12,
      },
      height: 16,
      minWidth: 16,
      px: 0.5,
      color: 'primary.700',
    },
  },
};

const MyBetsCountBadge = () => {
  const { data: myBetsCount } = useQuery({
    queryKey: [QUERY_KEYS.myBetsCount],
    queryFn: (): Promise<{ count: number }> => getData('betting-slips/my-bets/count-open'),
    select: (data) => data.count,
  });

  return <Badge badgeContent={myBetsCount} color='secondary' sx={styles.badge} />;
};

export default MyBetsCountBadge;
