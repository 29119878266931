import { Box, Drawer } from '@mui/material';
import { useMemo } from 'react';
import { StyleObj } from '../../@types';
import { DRAWER_CONFIG, drawerWidth } from '../../constants';
import { SidebarType } from '../../helpers';
import useBreakpoints from '../../hooks/useBreakpoints';
import { useSports } from '../../queries';
import AccountDrawerContent from '../atoms/AccountDrawerContent';
import BettingDrawerContent from '../atoms/BettingDrawerContent';
import InPlaySidebar from './InPlaySidebar';

type DrawerNavigationProps = {
  isOpen: boolean;
  toggleVisibility: (isOpen: boolean) => void;
  sidebarType: SidebarType;
};

const sharedWrapper = {
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  height: '100vh',
  overflowX: 'auto',
};

const makeStyles = (sidebarType: SidebarType): StyleObj => ({
  box: {
    ...sharedWrapper,
    backgroundColor: DRAWER_CONFIG[sidebarType].backgroundColor,
    pt: 8,
    width: DRAWER_CONFIG[sidebarType].width,
  },
  drawer: {
    ...sharedWrapper,
    backgroundColor: DRAWER_CONFIG[sidebarType].backgroundColor,
    '& .MuiDrawer-paper': {
      backgroundColor: DRAWER_CONFIG[sidebarType].backgroundColor,
      width: DRAWER_CONFIG[sidebarType].width,
    },
  },
  listItemButton: {
    paddingLeft: 0,
    paddingRight: 0,
  },
});

const DrawerNavigation = ({ isOpen, toggleVisibility, sidebarType }: DrawerNavigationProps) => {
  const { isMobile } = useBreakpoints();
  const styles = makeStyles(sidebarType);

  const { data: sportsData } = useSports({ enabled: sidebarType === 'betting' });

  const drawerContent = useMemo(() => {
    if (sidebarType === 'betting') {
      return <BettingDrawerContent data={sportsData || []} toggleVisibility={toggleVisibility} />;
    }

    if (sidebarType === 'inPlay') {
      return <InPlaySidebar toggleVisibility={toggleVisibility} />;
    }

    return <AccountDrawerContent toggleVisibility={toggleVisibility} />;
  }, [sidebarType, toggleVisibility, sportsData]);

  return isMobile ? (
    <Drawer
      sx={styles.drawer}
      open={isOpen}
      variant='temporary'
      transitionDuration={{ exit: 0 }}
      onClose={() => toggleVisibility(false)}
    >
      {drawerContent}
    </Drawer>
  ) : (
    <Box sx={styles.box}>{drawerContent}</Box>
  );
};

export default DrawerNavigation;
