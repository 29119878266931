import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material';
import { Grid, Stack, Pagination, PaginationItem } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import React, { useState } from 'react';
import { StyleObj, Transaction } from '../../@types';
import { postData } from '../../utils/api';
import Heading from '../atoms/Heading';
import StyledDatePicker from '../atoms/StyledDatePicker';
import HistoryTable from '../organisms/tables/HistoryTable';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import useBreakpoints from '../../hooks/useBreakpoints';
import { useQuery } from '@tanstack/react-query';

export const GET_TRANSACTIONS = '/transaction/transactions';

interface TransactionsReturnData {
  transactions: Transaction[];
  totalCount: number;
}

const styles: StyleObj = {
  container: {
    pr: {
      xs: 0,
      md: '16px',
    },
    width: '100%',
  },
  datePickerGroup: {
    width: '100%',
    my: {
      xs: 1.5,
      md: 2.5,
    },
    gap: {
      xs: 0,
      md: 2.5,
    },
  },
  pagination: {
    m: 'auto',
    mt: 2,
    '& button': {
      backgroundColor: 'primary.main',
      ':hover': {
        backgroundColor: 'primary.700',
      },
    },
  },
};

const CasinoHistoryPage = () => {
  const [page, setPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [fromDate, setFromDate] = useState<Dayjs | null>(dayjs().startOf('day'));
  const [toDate, setToDate] = useState<Dayjs | null>(dayjs().endOf('day'));
  const limit = 20;

  const { isMobile } = useBreakpoints();
  const queryConfig = () => ({
    queryKey: [fromDate, toDate, offset],
    queryFn: (): Promise<TransactionsReturnData> =>
      postData(
        'transaction/transactions?createdAt=desc',
        {
          offset,
          limit,
          dateFrom: fromDate?.valueOf(),
          dateTo: toDate?.valueOf(),
        },
        'casino'
      ),
  });

  const { data: casinoHistoryData } = useQuery(queryConfig());

  const casinoHistory = casinoHistoryData?.transactions || [];
  const count = casinoHistoryData?.totalCount ?? 0;

  const pageCount = Math.ceil(count / limit);
  const handlePagination = (page: number) => {
    setPage(page);
    setOffset((page - 1) * limit);
  };

  return (
    <Grid container>
      <Heading>
        <Heading.Title>Casino</Heading.Title>
      </Heading>
      <Stack sx={styles.container} alignItems='center'>
        <Stack direction='row' alignItems='center' justifyContent='flex-end' sx={styles.datePickerGroup}>
          <StyledDatePicker
            value={fromDate}
            onChange={setFromDate}
            minDate={dayjs().subtract(1, 'month').add(1, 'day')}
          />
          <ChevronRightIcon />
          <StyledDatePicker value={toDate} onChange={setToDate} />
        </Stack>
        <HistoryTable betHistory={casinoHistory} />
        <Pagination
          count={pageCount}
          page={page}
          onChange={(_, page) => handlePagination(page)}
          siblingCount={0}
          boundaryCount={0}
          sx={styles.pagination}
          renderItem={(item) => (
            <PaginationItem
              {...item}
              slots={{
                first: KeyboardDoubleArrowLeft,
                last: KeyboardDoubleArrowRight,
              }}
            />
          )}
          shape='rounded'
          showFirstButton
          showLastButton
          size={isMobile ? 'small' : 'medium'}
        />
      </Stack>
    </Grid>
  );
};

export default CasinoHistoryPage;
