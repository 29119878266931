import { ContentCopy, DoneAll } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { BetWithMarketOutcomeEvent, ResponseData, StyleObj } from '../../@types';
import { QUERY_KEYS } from '../../constants';
import { useBetslip } from '../../contexts/BetslipContext';
import { getData } from '../../utils/api';

const styles: StyleObj = {
  copyIcon: {
    color: 'neutral.200',
    fontSize: {
      xs: 16,
      md: 20,
    },
    transition: 'all 0.3s ease',
    ':hover': {
      color: 'neutral.50',
      cursor: 'pointer',
    },
  },
};

type Props = {
  betslipId: string;
};

const CopyBetslip = ({ betslipId }: Props) => {
  const [isCopySuccess, setIsCopySuccess] = useState(false);
  const { insertCopiedBetslip } = useBetslip();

  const { refetch } = useQuery({
    queryKey: [QUERY_KEYS.copyBetslip, betslipId],
    queryFn: (): Promise<ResponseData<BetWithMarketOutcomeEvent>> =>
      getData('/bets/copy', {
        bettingSlipId: betslipId,
      }),
    enabled: false,
    onSuccess: (data) => {
      // Return early if all the events have already started
      if (data.items.length === 0) return;

      const payload = data?.items?.map((bet) => ({
        eventId: bet.event.id,
        outcomeId: bet.outcome.id,
        odds: bet.outcome.odds,
        banker: bet.banker,
        eventName: bet.event.name,
        marketId: bet.market.id,
        marketName: bet.market.name,
        marketType: bet.market.marketType,
        outcomeName: bet.outcome.shortName || bet.outcome.name,
      }));

      if (payload) {
        insertCopiedBetslip(payload);
        setIsCopySuccess(true);
        setTimeout(() => setIsCopySuccess(false), 3000);
      }
    },
  });

  const copyBetslip = async (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    await refetch();
  };

  return isCopySuccess ? (
    <DoneAll sx={styles.copyIcon} />
  ) : (
    <Tooltip title='Copy'>
      <ContentCopy sx={styles.copyIcon} onClick={copyBetslip} />
    </Tooltip>
  );
};

export default CopyBetslip;
