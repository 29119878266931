import Dialog from '@mui/material/Dialog';
import React, { createContext, useContext, useState } from 'react';

interface IModalContext {
  openModal: (modalContent: React.ReactElement) => void;
  closeModal: () => void;
}

const ModalContext = createContext<IModalContext | null>(null);

interface ProviderProps {
  children: React.ReactElement;
}

export const ModalProvider = ({ children }: ProviderProps) => {
  const [toggleModal, setToggleModal] = useState<boolean>(false);
  const [content, setContent] = useState<React.ReactElement | null>(null);

  const openModal = (modalContent: React.ReactElement) => {
    setToggleModal(true);
    setContent(modalContent);
  };

  const closeModal = () => {
    setToggleModal(false);
    setContent(null);
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      <Dialog open={toggleModal} onClose={closeModal}>
        {content}
      </Dialog>
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);

  if (context === null) {
    throw new Error('useModal must be used within a Modal');
  }

  return context;
};
