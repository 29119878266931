import { Collapse, Stack, Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { StyleObj } from '../../@types';

type Props = {
  open: boolean;
  roundId: string;
  name: string;
  provider: string;
};

const makeStyles = (isOpen: boolean): StyleObj => ({
  collapsedContainer: (theme) => ({
    '& p': {
      maxWidth: {
        xs: window.innerWidth / 3,
        md: 'initial',
      },
    },
    '& .MuiTypography-caption': {
      marginLeft: 'auto',
      fontSize: 10,
    },
    '& .MuiTypography-body3': {
      fontSize: 12,
    },
    '&.MuiTableCell-body': {
      padding: '4px 0px',
      border: isOpen ? `1px solid ${theme.palette.neutral[400]}` : '1px solid transparent',
      background: isOpen ? `${theme.palette.neutral[400]}` : 'transparent',
    },
  }),
  collapsedRow: (theme) => ({
    borderBottom: `1px solid ${theme.palette.neutral[500]}`,
    padding: '12px 16px',
    '&:last-child': {
      borderBottom: 'none',
    },
  }),
});

const CasinoHistoryCollapsibleCell = ({ open, roundId, name, provider }: Props) => {
  const styles = makeStyles(open);

  return (
    <TableCell colSpan={5} sx={styles.collapsedContainer}>
      <Collapse in={open} timeout='auto' unmountOnExit>
        {
          <Stack>
            <Stack justifyContent='space-between' alignItems='center' direction='row' sx={styles.collapsedRow}>
              <Stack direction='row' spacing={1} flex={1}>
                <Typography variant='body2' fontWeight={600}>
                  {provider}
                </Typography>
              </Stack>
              <Typography variant='body2' fontWeight={600}>
                Round ID: {roundId}
              </Typography>
            </Stack>
            <Stack justifyContent='space-between' alignItems='center' direction='row' sx={styles.collapsedRow}>
              <Stack direction='row' spacing={1} flex={1}>
                <Typography variant='body2' fontWeight={600}>
                  {name}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        }
      </Collapse>
    </TableCell>
  );
};

export default CasinoHistoryCollapsibleCell;
