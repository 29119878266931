import { Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { Market, PublishedEventListItem, PublishedEventListWithTopMarketType } from '../../@types';
import { QUERY_KEYS } from '../../constants';
import { MessageBetType } from '../../helpers/betslipMessageParser';
import eventListItemUpdater from '../../helpers/eventListItemUpdater';
import topMarketUpdater from '../../helpers/eventTopMarketUpdater';
import useWebsocket, { MessageType } from '../../hooks/useWebsocket';
import { usePublishedEvents } from '../../queries';
import { getData } from '../../utils/api';
import Heading from '../atoms/Heading';
import EventsTable from '../organisms/tables/EventsTable';

type Props = {
  sportId?: string;
};

const InPlayPreviewTable = ({ sportId }: Props) => {
  const { data: liveEventsCount } = useQuery({
    queryKey: [QUERY_KEYS.liveEventsCount],
    queryFn: (): Promise<{ count: number }> => getData('events/live/count'),
  });

  const { data: upcomingLiveEventsData, isSuccess } = usePublishedEvents({
    queryKey: [QUERY_KEYS.upcomingLiveEvents],
    queryParams: {
      limit: 10,
      sportId,
      isLive: true,
    },
    options: {
      enabled: !!sportId,
    },
  });

  const [events, setEvents] = useState<PublishedEventListItem[]>([]);
  const [topMarkets, setTopMarkets] = useState<Record<string, Market | null>>({});

  const updaterCallback = useCallback((data: Record<string, MessageType<MessageBetType>>[]) => {
    setEvents((prevState) => {
      const newEvents = [...(prevState || [])];

      data.forEach((message) => {
        const updatedEventId = Object.keys(message)[0];
        const eventIndex = newEvents.findIndex((event) => event.id === updatedEventId);
        if (eventIndex === -1) return;
        const updatedEvent = eventListItemUpdater(newEvents[eventIndex], message[updatedEventId]);
        newEvents[eventIndex] = updatedEvent;
      });

      return newEvents;
    });
  }, []);

  const { joinRoom } = useWebsocket<MessageType<MessageBetType>>({
    callback: updaterCallback,
  });

  const topMarketUpdaterCallback = (data: Record<string, MessageType<MessageBetType>>[]) => {
    setTopMarkets((prevState) => {
      const newTopMarkets = { ...prevState };

      data.forEach((message) => {
        const updatedEventId = Object.keys(message)[0];
        const marketToUpdate = newTopMarkets[updatedEventId];
        if (!marketToUpdate) return;
        newTopMarkets[updatedEventId] = topMarketUpdater(marketToUpdate, message[updatedEventId]);
      });

      return newTopMarkets;
    });
  };

  const { joinRoom: joinTopMarketRoom } = useWebsocket<MessageType<MessageBetType>>({
    callback: topMarketUpdaterCallback,
  });

  const initializeTopMarkets = useCallback(
    (data?: PublishedEventListWithTopMarketType): Record<string, Market | null> => {
      const markets: Record<string, Market | null> = {};

      data?.items?.forEach((event) => {
        joinRoom(`${event.id}_reduced`);

        if (event.topMarket) {
          joinTopMarketRoom(`${event.id}_${event.topMarket?.marketType.id}`);
          markets[event.id] = event.topMarket;
        }
      });
      return markets;
    },
    [joinRoom, joinTopMarketRoom]
  );

  useEffect(() => {
    if (isSuccess && upcomingLiveEventsData) {
      setEvents(upcomingLiveEventsData.items || []);
      setTopMarkets(initializeTopMarkets(upcomingLiveEventsData));
    }
  }, [isSuccess, upcomingLiveEventsData, initializeTopMarkets]);

  return (
    <>
      <Heading to='../in-play'>
        <Heading.Title>
          Live{' '}
          <Typography component='span' variant='inherit' color='secondary'>
            IN-PLAY
          </Typography>
        </Heading.Title>
        <Heading.Link>{liveEventsCount?.count} Events</Heading.Link>
      </Heading>
      {!!events.length && sportId && (
        <EventsTable
          data={events.map((event) => ({
            ...event,
            topMarket: topMarkets[event.id],
          }))}
          showTableHead
          topMarketType={upcomingLiveEventsData?.topMarketType}
          inPlay
        />
      )}
    </>
  );
};

export default InPlayPreviewTable;
