import { Breakpoint, useMediaQuery, useTheme } from '@mui/material';

const useBreakpoints = () => {
  const theme = useTheme();

  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  const md = useMediaQuery(theme.breakpoints.only('md'));
  const lg = useMediaQuery(theme.breakpoints.only('lg'));
  const xl = useMediaQuery(theme.breakpoints.up('xl'));

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const isSmallDesktop = useMediaQuery('(max-width:1024px)');

  const CURRENT_BREAKPOINT_MAP: Record<Breakpoint, boolean> = {
    xs,
    sm,
    md,
    lg,
    xl,
  } as const;

  const activeBreakpoint = Object.keys(CURRENT_BREAKPOINT_MAP).find(
    (breakpoint) => CURRENT_BREAKPOINT_MAP[breakpoint as Breakpoint]
  ) as Breakpoint | undefined;

  return {
    isMobile,
    isTablet,
    isSmallDesktop,
    activeBreakpoint,
  };
};

export default useBreakpoints;
