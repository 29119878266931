import { BetslipWithDetails, HistoryRowData, Transaction } from '../@types';

let rowData: HistoryRowData = {
  betType: '',
  dateTime: '',
  stakeAmount: null,
};
export const getHistoryRowData = (data: BetslipWithDetails | Transaction) => {
  if ('bettingSlipType' in data) {
    rowData = {
      betType: data.bettingSlipType,
      betId: data.id,
      ticketId: data.ticketId,
      dateTime: data.createdAt,
      stakeAmount: data.totalStakeAmount,
      possibleReturn: data.possibleWinnings,
      status: data.status,
      winnings: data.winnings,
      jackpot: data.jackpot,
    };
  } else if ('transactionType' in data) {
    rowData = {
      betType: data.transactionType,
      roundId: data.roundId,
      dateTime: data.createdAt,
      stakeAmount: data.transactionAmount,
      previousBalance: data.oldBalance,
      balance: data.balance,
      currency: data.currency,
      gameName: data.gameName,
      providerName: data.providerName,
    };
  }
  return rowData;
};
