import { hexToRgb } from '@mui/material';
import {
  CurrentEventPart,
  EventPartScore,
  EventsGroupedByTournament,
  Market,
  MarketsGroupedByMarketType,
  PublishedEventListItem,
  WSMessagePayloadType,
} from '../@types';
import { PublishedEventWithMarketsAndScores } from '../helpers/eventUpdater';

const isMultipleDisplayType = (market: Market) => {
  return !market.isSingleDisplayType && market.specialValues?.length > 0;
};

export const formatMarketsData = (markets: Market[]) => {
  const result: (Market | MarketsGroupedByMarketType)[] = [];

  markets.forEach((market) => {
    if (isMultipleDisplayType(market)) {
      const existingItem = result.find((item) => item.id === market.marketTypeId);

      if (existingItem && 'markets' in existingItem) {
        existingItem.markets.push(market);
      } else {
        const marketName = market.marketType?.shortName || market.marketType?.name || '';
        result.push({
          id: market.marketTypeId,
          marketType: market.marketTypeId,
          name: `${marketName}, ${market.marketType?.eventPartName}`,
          shortName: market.shortName,
          markets: [market],
          isCollapsedMode: market.isCollapsedMode,
          position: market.position,
          favourite: market.favourite,
        });
      }
    } else {
      result.push(market);
    }
  });

  return result;
};

export const sortEventsByFavorite = (events: PublishedEventListItem[]) => {
  return events.sort((a, b) => {
    if (a.favourite && !b.favourite) return -1;
    if (!a.favourite && b.favourite) return 1;
    return 0;
  });
};

const addEventToTournamentGroup = (
  group: EventsGroupedByTournament,
  tournamentId: string,
  prefix: string,
  event: PublishedEventListItem
): void => {
  // Prefix the key to separately show the same tournament containing favorited and non-favorited events
  const prefixedId = `${prefix}_${tournamentId}`;
  const tournamentData = group[prefixedId] ?? { tournament: event.tournament, events: [] };
  tournamentData.events.push(event);
  group[prefixedId] = tournamentData;
};

export const groupEventsByTournament = (events: PublishedEventListItem[]): EventsGroupedByTournament => {
  /*
  If a tournament is favorited, it should be shown first, regardless of its events' favorite status.
  If an event from a non-favorited tournament is favorited, we split its tournament view into two tables,
      one for favorited events and one for non-favorited events.
  */
  const favoriteTournaments: EventsGroupedByTournament = {};
  const tournamentsWithFavoriteEvents: EventsGroupedByTournament = {};
  const tournamentsWithoutFavoriteEvents: EventsGroupedByTournament = {};

  events.forEach((event) => {
    const tournamentId = event.tournament?.id;
    if (!tournamentId) return;

    if (event.tournament?.favourite) {
      addEventToTournamentGroup(favoriteTournaments, tournamentId, 'fav', event);
    } else if (event.favourite) {
      addEventToTournamentGroup(tournamentsWithFavoriteEvents, tournamentId, 'withFav', event);
    } else {
      addEventToTournamentGroup(tournamentsWithoutFavoriteEvents, tournamentId, 'nonFav', event);
    }
  });

  // Show favorite events above non-favorited events inside the favorite tournament table
  Object.values(favoriteTournaments).forEach((tournamentData) => sortEventsByFavorite(tournamentData.events));

  return {
    ...favoriteTournaments,
    ...tournamentsWithFavoriteEvents,
    ...tournamentsWithoutFavoriteEvents,
  };
};

export const isCheckboxClicked = (
  event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.SyntheticEvent
): boolean => {
  return event.target instanceof HTMLInputElement && event.target.type === 'checkbox';
};

export const getReofferedColor = <T>(currentValue: T, previousValue: T) => {
  let isEqual;
  let isLessThan;
  let color;

  // Special values
  if (Array.isArray(currentValue) && Array.isArray(previousValue)) {
    isEqual = currentValue.every(({ value }, index) => value === previousValue[index].value);
    isLessThan = currentValue.some(({ value }, index) => value < previousValue[index].value);
  } else {
    isEqual = currentValue === previousValue;
    isLessThan = currentValue < previousValue;
  }

  if (isEqual) {
    color = 'neutral.600';
  } else if (isLessThan) {
    color = 'error.600';
  } else {
    color = 'primary.main';
  }

  return { color, changed: !isEqual };
};

export const formatScore = (mainEventPartScore: EventPartScore | null) => {
  const { valueAway, valueHome } = mainEventPartScore || {};

  if ((valueHome || valueHome === 0) && (valueAway || valueAway === 0)) {
    return `${valueHome} - ${valueAway}`;
  }
  return null;
};

export const updateEventPartScores = (
  scores: EventPartScore[] | undefined,
  payload: WSMessagePayloadType,
  callback?: () => void
): EventPartScore[] => {
  if (!scores) return [];

  const { id, valueHome, valueAway } = payload;
  const scoreIndex = scores.findIndex((score) => score.id === id);

  if (scoreIndex !== -1) {
    scores[scoreIndex] = {
      ...scores[scoreIndex],
      valueHome: valueHome ?? scores[scoreIndex].valueHome,
      valueAway: valueAway ?? scores[scoreIndex].valueAway,
    };
  } else {
    callback?.();
  }

  return scores;
};

export const updateEvent = (
  prev: PublishedEventListItem | PublishedEventWithMarketsAndScores,
  payload: WSMessagePayloadType
): PublishedEventListItem | PublishedEventWithMarketsAndScores => {
  if ('isActive' in payload) {
    prev.isActive = payload.isActive || false;
  }
  if ('matchTime' in payload) {
    prev.matchTime = payload.matchTime || '';
  }
  if ('highlightedParticipantId' in payload) {
    const { highlightedParticipantId } = payload;
    if (prev.participants) {
      prev.participants.away.isHighlighted = prev.participants.away.id === highlightedParticipantId;
      prev.participants.home.isHighlighted = prev.participants.home.id === highlightedParticipantId;
    }
  }
  if ('currentEventPart' in payload) {
    prev.currentEventPart = payload.currentEventPart as CurrentEventPart;
  }

  return prev;
};

export const compareNullable = (operation: 'min' | 'max', ...args: (number | null | undefined)[]) => {
  const validNumbers = args.filter((arg): arg is number => typeof arg === 'number');

  if (validNumbers.length === 0) {
    return null;
  }

  return operation === 'max' ? Math.max(...validNumbers) : Math.min(...validNumbers);
};

export const maxNullable = (...args: (number | null | undefined)[]) => compareNullable('max', ...args);
export const minNullable = (...args: (number | null | undefined)[]) => compareNullable('min', ...args);

export const isTruthy = <T>(value?: T | undefined | null | false): value is T => {
  return !!value;
};

export const hexToRgba = (hex: string, alpha: number) => {
  const rgb = hexToRgb(hex);

  return rgb.replace('rgb', 'rgba').replace(')', `, ${alpha})`);
};
