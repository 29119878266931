import React from 'react';
import { Table } from '@mui/material';
import { tableHeadClasses } from '@mui/material/TableHead';
import { tableCellClasses } from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import HistoryTableRow from '../HistoryTableRow';
import TableContainer from '@mui/material/TableContainer';
import { BetslipWithDetails, StyleObj, Transaction } from '../../../@types';
import BetHistoryTableHead from '../../molecules/BetHistoryTableHead';
import useBreakpoints from '../../../hooks/useBreakpoints';
import { getHistoryRowData } from '../../../utils/historyTable';

type Props = {
  betHistory: BetslipWithDetails[] | Transaction[];
};

const styles: StyleObj = {
  table: (theme) => ({
    '& p': {
      fontSize: {
        xs: '10px',
        md: 'initial',
      },
      lineHeight: {
        xs: '12px',
        md: 'initial',
      },
      maxWidth: {
        xs: `${window.innerWidth / 5 - 12}px`,
        md: 'initial',
      },
    },
    '& span': {
      fontSize: {
        xs: '10px',
        md: 'initial',
      },
      lineHeight: {
        xs: '12px',
        md: 'initial',
      },
    },
    [`& .${tableHeadClasses.root}`]: {
      border: `1px solid ${theme.palette.neutral[600]}`,
      background: 'transparent',
    },
    [`& .${tableCellClasses.body}`]: {
      borderBlock: '1px solid transparent',
      borderInline: `1px solid ${theme.palette.neutral[600]}`,
      background: theme.palette.neutral[600],
      maxWidth: {
        xs: `${window.innerWidth / 5}px`,
        md: 'initial',
      },
      padding: {
        xs: '6px',
        md: '16px',
      },
    },
    [`& .${tableCellClasses.head}`]: {
      borderBottom: `1px solid ${theme.palette.neutral[600]}`,
      maxWidth: {
        xs: `${window.innerWidth / 5}px`,
        md: 'initial',
      },
      padding: {
        xs: '6px',
        md: '16px',
      },
    },
  }),
  tableContainer: {
    height: {
      xs: 'calc(100vh - 280px)',
      md: 'calc(100vh - 335px)',
    },
  },
};

const HistoryTable = ({ betHistory }: Props) => {
  const { isMobile } = useBreakpoints();
  const rowData = betHistory.map((data) => getHistoryRowData(data));
  return (
    <TableContainer sx={styles.tableContainer}>
      <Table sx={styles.table} size={isMobile ? 'small' : 'medium'} stickyHeader>
        <BetHistoryTableHead />
        <TableBody>
          {rowData.map((row) => (
            <HistoryTableRow row={row} key={row.dateTime} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HistoryTable;
