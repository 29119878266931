import { SVGProps } from 'react';
import { RESOLVE_STATUS_COLORS } from '../../constants';
import { BetStatus } from '../../@types';

type ResolveStatusIconProps = SVGProps<SVGSVGElement> & {
  status: BetStatus;
  id: string;
};

const ResolveStatusIcon = (props: ResolveStatusIconProps) => {
  const { status, id } = props;

  const fill = RESOLVE_STATUS_COLORS[status];

  return (
    <svg width='20' height='20' fill='none' viewBox='0 0 24 24'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z'
        fill={typeof fill === 'string' ? fill : fill?.[0]}
      />
      {typeof fill !== 'string' && (
        <>
          <g clipPath={`url(#${id})`}>
            <path fillRule='evenodd' clipRule='evenodd' d='M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z' fill={fill?.[1]} />
          </g>
          <defs>
            <clipPath id={id}>
              <path fill='#fff' transform='translate(12 4)' d='M0 0h8v16H0z' />
            </clipPath>
          </defs>
        </>
      )}
    </svg>
  );
};

export default ResolveStatusIcon;
