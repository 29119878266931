const HistoryIcon = () => {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16 0C12.8355 0 9.74207 0.938383 7.11088 2.69649C4.4797 4.45459 2.42894 6.95345 1.21793 9.87706C0.0069325 12.8007 -0.309921 16.0177 0.307443 19.1214C0.924806 22.2251 2.44866 25.0761 4.6863 27.3137C6.92394 29.5513 9.77486 31.0752 12.8786 31.6926C15.9823 32.3099 19.1993 31.9931 22.1229 30.7821C25.0466 29.5711 27.5454 27.5203 29.3035 24.8891C31.0616 22.2579 32 19.1645 32 16C32 11.7565 30.3143 7.68687 27.3137 4.68629C24.3131 1.68571 20.2435 0 16 0ZM16 30C13.2311 30 10.5243 29.1789 8.22202 27.6406C5.91973 26.1022 4.12532 23.9157 3.06569 21.3576C2.00607 18.7994 1.72882 15.9845 2.26901 13.2687C2.80921 10.553 4.14258 8.05844 6.10051 6.1005C8.05844 4.14257 10.553 2.8092 13.2687 2.26901C15.9845 1.72881 18.7994 2.00606 21.3576 3.06569C23.9157 4.12531 26.1022 5.91973 27.6406 8.22201C29.1789 10.5243 30 13.2311 30 16C30 19.713 28.525 23.274 25.8995 25.8995C23.274 28.525 19.713 30 16 30Z'
        fill='currentColor'
      />
      <path
        d='M16.9199 16.4V8.75C16.9199 8.48478 16.8146 8.23043 16.627 8.04289C16.4395 7.85536 16.1851 7.75 15.9199 7.75C15.6547 7.75 15.4004 7.85536 15.2128 8.04289C15.0253 8.23043 14.9199 8.48478 14.9199 8.75V17.47L20.8199 21.47C20.9286 21.5517 21.0528 21.6105 21.185 21.6426C21.3171 21.6747 21.4544 21.6795 21.5885 21.6567C21.7226 21.6339 21.8506 21.584 21.9647 21.5101C22.0789 21.4362 22.1767 21.3397 22.2523 21.2267C22.3279 21.1136 22.3796 20.9864 22.4044 20.8526C22.4291 20.7189 22.4263 20.5815 22.3961 20.4489C22.3659 20.3163 22.309 20.1913 22.2289 20.0814C22.1487 19.9716 22.047 19.8792 21.9299 19.81L16.9199 16.4Z'
        fill='currentColor'
      />
      <path
        d='M5.99971 15.9421C5.99057 14.1431 6.46981 12.3754 7.38635 10.8274C8.30288 9.27938 9.62234 8.00913 11.2041 7.15208C12.7858 6.29502 14.5704 5.88328 16.3677 5.96077C18.165 6.03826 19.9076 6.60206 21.4097 7.59207L22.2597 6.23207C20.8329 5.29747 19.2169 4.69047 17.5277 4.45466C15.8384 4.21886 14.1179 4.36011 12.4898 4.86827C10.8616 5.37643 9.36618 6.23888 8.11095 7.39366C6.85572 8.54845 5.87182 9.96691 5.22995 11.5471C4.58807 13.1274 4.30415 14.8302 4.39857 16.5332C4.493 18.2362 4.96342 19.8971 5.77606 21.3967C6.58869 22.8963 7.72337 24.1973 9.09857 25.2062C10.4738 26.2152 12.0554 26.907 13.7297 27.2321L13.9997 25.7021C11.7477 25.2424 9.72277 24.021 8.26572 22.2434C6.80867 20.4658 6.00844 18.2405 5.99971 15.9421Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default HistoryIcon;
