const messageGenerators = {
  noUpcomingEvents: (type: string, parent: string) => `There are currently no upcoming ${type} for this ${parent}.`,
};

export const MESSAGES = {
  maxSelections: 'Maximum number of selections already made',
  availabilityChanged: 'Availability of your selection(s) changed',
  awaitingConfirmation: 'Awaiting confirmation...',
  placeBetSuccess: 'Confirmed.',
  outcome: 'The price of your selection(s) changed',
  insufficientFunds: 'You have insufficient funds',
  maxBankers: 'Maximum number of selections added as banker',
  noOpenBets: 'There are currently no open bets to display',
  noResolvedBets:
    'There are currently no resolved bets to display\nBets that are resolved will appear here for 24 hours\nView older resolved bets in your',
  noCashoutBetslips: 'There are currently no tickets eligible for cash out',
  accountHistory: 'Account History',
  ticketCopied: 'Ticket successfully copied',
  rejected: ' rejected.',
  bettingSlipRejected: 'Betting slip rejected.',
  requireConfirmation: 'require confirmation.',
  notAllowedToBet: 'Bet cannot be placed while previous ticket is awaiting confirmation.',
  eventStarted: 'This event has already started.',
  eventNotFound: 'Event not found.',
  noUpcomingMatches: messageGenerators.noUpcomingEvents('matches', 'tournament'),
  noUpcomingOutrights: messageGenerators.noUpcomingEvents('outrights', 'tournament'),
  noUpcomingSportMatches: messageGenerators.noUpcomingEvents('matches', 'sport'),
  noUpcomingSportOutrights: messageGenerators.noUpcomingEvents('outrights', 'sport'),
  noAvailableMarkets: 'There are currently no available markets for this group.',
  matchFinished: 'Match Finished',
  maintenanceMode: 'Our website is currently in maintenance mode.',
  maintenanceApology:
    'We apologize for any inconvenience this may cause and appreciate your patience. Please check back soon!',
} as const;
