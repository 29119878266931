import { useEffect, useState } from 'react';
import { TabConfigItem } from '../@types';

type UseTabsProps<T> = {
  defaultTab?: T;
  tabConfig: TabConfigItem<T>[];
  onChange?: (selectedTab: T) => void;
};

const useTabs = <T>({ defaultTab, tabConfig, onChange }: UseTabsProps<T>) => {
  const [activeTab, setActiveTab] = useState<T>(defaultTab || tabConfig[0]?.value);

  useEffect(() => {
    setActiveTab(defaultTab || tabConfig[0]?.value);
  }, [defaultTab, tabConfig]);

  const handleTabChange = (_event: React.SyntheticEvent, selectedTab: T) => {
    setActiveTab(selectedTab);
    onChange?.(selectedTab);
  };

  return {
    value: activeTab,
    setValue: setActiveTab,
    onChange: handleTabChange,
  };
};

export default useTabs;
