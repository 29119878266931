import { Stack, TableCell, TableCellProps, Typography } from '@mui/material';
import { isEmpty, merge } from 'lodash-es';
import { Outcome, SpecialValue, StyleObj } from '../../@types';
import { useBetslip } from '../../contexts/BetslipContext';
import { getOutcomeName } from '../../helpers';
import { BetDetails } from './BetDetails';

const makeStyles = (selected: boolean, hideOutcomeName?: boolean, disabled?: boolean): StyleObj => ({
  outcomeCell: {
    flex: 1,
    color: selected ? 'primary.600' : 'secondary.main',
    backgroundColor: selected ? 'neutral.100' : 'neutral.800',
    opacity: disabled ? 0.3 : 1,
    cursor: disabled ? 'not-allowed' : 'pointer',
    borderBottom: '1px solid',
    borderBottomColor: hideOutcomeName ? 'neutral.600' : 'primary.main',
    p: {
      xs: 1,
      md: 2,
    },
    '&:hover': {
      backgroundColor: disabled ? 'neutral.800' : selected ? 'neutral.100' : 'neutral.600',
    },
    '& span': {
      fontSize: {
        xs: 13,
        md: 15,
      },
    },
  },
  oddsStack: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
    gap: {
      xs: 0.25,
      md: 0.5,
    },
  },
});

type OutcomeTableCellProps = TableCellProps & {
  outcome?: Outcome | null; // optional because we want to render empty cell if there is no outcome
  disabled?: boolean;
  specialValues?: SpecialValue[];
  onClick: () => void;
  hideOutcomeName?: boolean;
  isMarketSingleDisplayType: boolean;
  marketTypeName?: string;
};

const OutcomeTableCell = ({
  outcome,
  specialValues,
  onClick,
  hideOutcomeName = false, // used to hide outcome name and special values on In play page
  disabled,
  isMarketSingleDisplayType,
  marketTypeName,
  ...rest
}: OutcomeTableCellProps) => {
  const { isOutcomeSelected } = useBetslip();

  const isEmptyCell = !outcome || outcome.odds === '1.00';

  const selected = !isEmptyCell ? isOutcomeSelected(outcome.id) : false;
  const hasSpecialValues = outcome && !isEmpty(specialValues);

  const interactionDisabled = disabled || isEmptyCell || !outcome?.isAvailable;

  const styles = makeStyles(selected, hideOutcomeName, interactionDisabled);

  const outcomeName =
    outcome && !hideOutcomeName && !hasSpecialValues ? getOutcomeName(outcome, isMarketSingleDisplayType) : null;

  if (isEmptyCell) {
    return (
      <TableCell align='center' sx={styles.outcomeCell}>
        <Typography color='white' component='span' textAlign='center'>
          -
        </Typography>
      </TableCell>
    );
  }

  return (
    <TableCell
      align='center'
      onClick={interactionDisabled ? undefined : onClick}
      sx={merge(styles.outcomeCell, rest.sx)}
    >
      <Stack sx={styles.oddsStack}>
        <BetDetails.SpecialValues
          outcomeName={outcome.name}
          specialValues={specialValues}
          marketTypeName={marketTypeName}
          color={selected ? 'neutral.600' : 'white'}
          wrapInParenthesis={false}
        />
        {outcomeName && <BetDetails.Outcome outcomeName={outcomeName} color={selected ? 'neutral.600' : 'white'} />}
        <Typography id={outcome.id} component='span'>
          {outcome.odds}
        </Typography>
      </Stack>
    </TableCell>
  );
};

export default OutcomeTableCell;
