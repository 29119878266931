import { QueryKey, UseQueryOptions, useQuery } from '@tanstack/react-query';
import {
  Competition,
  EventType,
  GlobalTicketConditionsResponse,
  JackpotPool,
  MarketGroup,
  PaginatedData,
  PublishedEventListWithTopMarketType,
  ResponseData,
  Scoreboard,
  Sport,
  UserBalance,
  UserSettings,
} from '../@types';
import { CURRENCY, QUERY_KEYS, SECOND } from '../constants';
import { getData } from '../utils/api';
import { generateMixTicketConditions } from '../helpers';

export const useBalances = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.balance],
    queryFn: (): Promise<ResponseData<UserBalance>> =>
      getData('wallets/balances', { currency: CURRENCY.code }, 'wallet'),
  });
};

export const useCountPendingBetslips = (options?: UseQueryOptions<{ count: number }>) => {
  return useQuery({
    queryKey: [QUERY_KEYS.pendingBetslipsCount],
    queryFn: (): Promise<{ count: number }> => getData('/betting-slips/my-bets/count-pending'),
    ...options,
  });
};

export const useGetEventScoreboard = (eventId: string, options?: UseQueryOptions<Scoreboard>) => {
  return useQuery({
    queryKey: [QUERY_KEYS.eventPartScores, eventId],
    queryFn: (): Promise<Scoreboard> => getData(`events/${eventId}/scoreboard`),
    enabled: !!eventId,
    ...options,
    cacheTime: 0,
  });
};

export const useSports = (options?: Pick<UseQueryOptions<PaginatedData<Sport>>, 'enabled'>) => {
  return useQuery({
    queryKey: [QUERY_KEYS.sports],
    queryFn: (): Promise<PaginatedData<Sport>> =>
      getData('sports', {
        page: 1,
        limit: 20,
        isActive: true,
        withPublishedEvents: true,
      }),
    select: (data) => data.items,
    ...options,
  });
};

export const useLiveSports = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.liveSports],
    queryFn: (): Promise<PaginatedData<Sport>> => getData('sports/with-live-events'),
  });
};

export const useSportById = (sportId: string | undefined) => {
  return useQuery({
    queryKey: [QUERY_KEYS.sports, sportId],
    queryFn: (): Promise<{ item: Sport }> => getData(`sports/${sportId}`),
    enabled: !!sportId,
    select: (data) => data.item,
  });
};

export const useFavoriteEventsCount = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.favoriteEvents, 'count'],
    queryFn: (): Promise<{ count: number }> => getData('user-settings/events/favourites/count'),
  });
};

export const useMarketGroups = (sportId: string | undefined, queryParams: { [key: string]: unknown } = {}) => {
  return useQuery({
    queryKey: [QUERY_KEYS.marketGroups, sportId, queryParams],
    queryFn: (): Promise<{ items: MarketGroup[] }> =>
      getData('market-type-groups', {
        sportId,
        displayType: 'match',
        sort: 'position',
        isActive: true,
        limit: 100,
        ...queryParams,
      }),
    enabled: !!sportId,
    select: (data) => data.items,
  });
};

type PublishedEventQuery = {
  queryKey?: QueryKey;
  queryParams?: { [key: string]: unknown };
  options?: Pick<UseQueryOptions<PublishedEventListWithTopMarketType>, 'enabled' | 'onSuccess'>;
};

export const usePublishedEvents = ({
  queryKey = [QUERY_KEYS.publishedEvents],
  queryParams,
  options,
}: PublishedEventQuery = {}) => {
  return useQuery({
    queryKey,
    queryFn: (): Promise<PublishedEventListWithTopMarketType> =>
      getData('events/published', {
        page: 1,
        limit: 25,
        isActive: true,
        ...queryParams,
      }),
    ...options,
  });
};

export const useJackpotPools = () =>
  useQuery({
    queryKey: [QUERY_KEYS.jackpots],
    queryFn: (): Promise<PaginatedData<JackpotPool>> =>
      getData('jackpots/pools', {
        page: 1,
        limit: 100,
        product: 'sportsbook',
      }),
    select: (data) => data.items,
    refetchInterval: 30 * 1000,
  });

export const useCompetitions = ({ sportId, type = 'match' }: { sportId: string | undefined; type: EventType }) =>
  useQuery({
    queryKey: [QUERY_KEYS.competitions, sportId, type],
    queryFn: (): Promise<PaginatedData<Competition>> =>
      getData('competitions/with-events', {
        limit: 200,
        sportId,
        type,
      }),
    enabled: !!sportId,
  });

export const useGlobalTicketConditions = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.ticketConditions],
    queryFn: (): Promise<GlobalTicketConditionsResponse> => getData('conditions/global/ticket'),
    select: (data) => generateMixTicketConditions(data),
  });
};

export const useUserSettings = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.userSettings],
    queryFn: (): Promise<UserSettings> => getData('user-settings'),
  });
};

export const useUnreadMessageCount = () =>
  useQuery({
    queryKey: [QUERY_KEYS.messageCount],
    queryFn: (): Promise<number> => getData('/messages-client/count'),
    refetchInterval: 60 * SECOND,
  });
